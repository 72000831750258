import React, { Fragment, useLayoutEffect, useRef } from 'react';
import gsap from 'gsap';
import { Power2 } from 'gsap';
import { Facebook, Linkedin, Youtube } from '../assets/shapes/shape';
const SocialMedia = () => {
  const tl = gsap.timeline();
  let root = useRef(null);
  const socialMediaData = [
    {
      name: 'Facebook',
      link: 'https://www.facebook.com/gbsplus',
      icon: <Facebook />,
    },
    {
      name: 'Youtube',
      link: 'https://www.youtube.com/@gbs-pluspvtltd7834',
      icon: <Youtube />,
    },
    {
      name: 'LinkedIn',
      link: 'https://www.linkedin.com/company/gbs-plus/mycompany/',
      icon: <Linkedin />,
    },
  ];
  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      tl.to(
        '.swiper-pagination-clickable',
        {
          opacity: 1,
          y: -10,
          ease: Power2.easeInOut,
          stagger: 0.1,
          duration: 0.5,
        },
        0.1
      );
    }, root);
    return () => ctx.revert();
  }, []);
  return (
    <Fragment>
      <ul ref={root} className='position-absolute social-media-links'>
        {socialMediaData.map((list, index) => {
          const { name, link, icon } = list;
          return (
            <li key={index} className='swiper-pagination-clickable'>
              <a href={link} target='_blank'>
                {icon} {name}
              </a>
            </li>
          );
        })}
      </ul>
    </Fragment>
  );
};

export default SocialMedia;
