import React, { Fragment, useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import {
  EffectFade,
  EffectCoverflow,
  Navigation,
  Pagination,
  Autoplay,
} from 'swiper';
import imgs from '../../assets/images/our_complete_1.png';
import imgs2 from '../../assets/images/our_complete_2.png';
import clientsection_1 from '../../assets/images/client_list_1.png';
import clientsection_2 from '../../assets/images/client_list_2.png';
import clientsection_3 from '../../assets/images/client_list_3.png';

import { ClientsListHead, OurCompleteHead } from '../../assets/shapes/shape';
const HomeCompleteProject = () => {
  const ourCompleteImages = [
    {
      src: imgs,
      name: 'HRMS',
    },
    {
      src: imgs2,
      name: 'CMS (Club Management System)',
    },
  ];
  const clientList = [
    {
      src: clientsection_1,
    },
    {
      src: clientsection_2,
    },
    {
      src: clientsection_3,
    },
  ];
  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  return (
    <Fragment>
      <section className='gap section-home-vission-mission'>
        <div className='bg-proj-completed'>
          <div className='container'>
            <header>
              <OurCompleteHead />
            </header>

            <Swiper
              effect={'coverflow'}
              grabCursor={true}
              centeredSlides={true}
              slidesPerView={width < 991 ? 1 : 2}
              autoplay={{
                delay: 3500,
                disableOnInteraction: true,
              }}
              coverflowEffect={{
                rotate: 50,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: true,
              }}
              speed={2500}
              pagination={true}
              modules={[Autoplay, EffectCoverflow, Pagination]}
              className='mySwiper'
            >
              {ourCompleteImages.map((list, index) => {
                return (
                  <SwiperSlide key={index}>
                    <h6
                      className='text-left position-absolute'
                      style={{
                        left: '0.5rem',
                        top: '0.5rem',
                        fontSize: '11px',
                        color: 'rgba(0,0,0,0.64)',
                      }}
                    >
                      {list.name}
                    </h6>
                    <img src={list.src} />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      </section>
      <section className='gap client-list section-home-vission-mission'>
        <div className='container'>
          <header className='p-0'>
            <ClientsListHead />
          </header>
          <Swiper
            centeredSlides={true}
            grabCursor={true}
            slidesPerView={'auto'}
            // autoplay={{
            //   delay: 3500,
            //   disableOnInteraction: false,
            // }}
            pagination={{
              clickable: true,
            }}
            speed={2000}
            loop={false}
            navigation={false}
            modules={[Pagination, Navigation, Autoplay, EffectFade]}
            effect={'slide'}
            className='mySwiper'
          >
            {clientList.map((list, index) => {
              return (
                <SwiperSlide key={index}>
                  <img className='pt-4' src={list.src} />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </section>
    </Fragment>
  );
};

export default HomeCompleteProject;
