import React, { Fragment } from 'react';
import Navbar from '../compontents/Navbar';
import { BannerSmall, FooterCommon, PlatformsContent } from '../compontents';
import PlatformsImage from '../assets/images/platform_banner_img.png';
const Platforms = () => {
  return (
    <Fragment>
      <Navbar />
      <BannerSmall
        image={PlatformsImage}
        styleClass={'offerings'}
        headding={'Platforms'}
      />
      <PlatformsContent />

      <FooterCommon />
    </Fragment>
  );
};

export default Platforms;
