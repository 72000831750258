import React from 'react';
import ClientsTab from './ClientsTab';

const ClientsComponents = () => {
  return (
    <div className='container'>
      <ClientsTab />
    </div>
  );
};

export default ClientsComponents;
