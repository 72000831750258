import React, { Fragment, useLayoutEffect, useRef } from 'react';
import aboutContent from '../../assets/images/about-content-img.png';
import gsap from 'gsap';
import { Power2 } from 'gsap';
const AboutContentSction = () => {
  let root = useRef(null);
  useLayoutEffect(() => {
    const tl = gsap.timeline();
    let ctx = gsap.context(() => {
      tl.from(
        '#img-Animate',
        {
          opacity: 0,
          ease: Power2.easeIn,
        },
        '0.1'
      ).to(
        '.content p',
        {
          opacity: 1,
          y: -10,
          ease: Power2.easeInOut,
          stagger: 0.1,
          duration: 0.5,
        },
        0.3
      );
    }, root);
    return () => ctx.revert();
  }, []);
  return (
    <Fragment>
      <div className='gap' ref={root} id='about'>
        <div className='container gap-bottom'>
          <div className='row'>
            <div className='col-lg-6'>
              <img
                id='img-Animate'
                className='img-fluid'
                src={aboutContent}
                alt='aboutContent'
              />
            </div>
            <div className='col-lg-6 content'>
              <p>
                <strong>GBS-PLUS</strong> is a software solutions and product
                company based in Trivandrum. We focus on providing innovative
                solutions using the latest cutting edge software technologies
                available.
              </p>
              <p>
                100+ years of collective experience by the founding team members
                in designing and developing software products and solutions. In
                their previous responsibilities, founders have played key roles
                in successfully building products based solutions for world’s
                top financial and telecom companies. A well-established
                development process, knowledge of the latest technologies,
                expert management, effective communication and full-cycle
                quality assurance is what we are trying to maintain to develop
                our products.
              </p>
              <p>
                We are a team of architects , designers and developers whose
                core strength is in the design and development of large scale ,
                fault tolerant systems with high performance and scalability .
                We also have expertise in the performance tuning of software
                systems handling huge load with emphasis on achieving maximum
                throughput and response times with optimal utilization of
                resources. Our expertise is also in developing web application
                which can handle thousands of concurrent requests with high
                response times by optimizing usage of resources.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AboutContentSction;
