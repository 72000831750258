import React, { Fragment } from 'react';
import ReachYourContent from '../../assets/images/reach_your_customer.png';
import TeachStudents from '../../assets/images/teach_students.png';
import SellingBecome from '../../assets/images/selling_becomes.png';
import CloudComputing from '../../assets/images/cloud_computing.png';
import DataMoney from '../../assets/images/data_money.png';
import {
  ReachYourCustomerHead,
  TeachStudentsHead,
  SellingBecomesHead,
  CloudComputingHead,
  DataMoneyHead,
} from '../../assets/shapes/shape';
import ImgLeftComponent from './ImgLeftComponent';
import ImgRightContent from './ImgRightContent';

const OfferingContent = () => {
  const offeringData = [
    {
      imgpos: 'L',
      headding: <ReachYourCustomerHead />,
      content:
        'Best Software solutions, which are complete experiences that give your online visitors an accurate sense of your facilities and features. They are created from real passion and accurate data about your customers, and you can embed the walkthroughs in a website just like photos and videos.',
      img: ReachYourContent,
      id: 'reach-customer',
    },
    {
      imgpos: 'R',
      headding: <TeachStudentsHead />,
      content:
        'We have designed solutions for various types of institutions in educational sector to carry out their day to day activities in automated and simplified manner. These have designed for better interaction between students, teachers, parents and management. It gracefully handles all the requirements of an easy management.',
      img: TeachStudents,
      id: 'teach-students',
    },
    {
      imgpos: 'L',
      headding: <SellingBecomesHead />,
      content:
        'Buying recommendations can play a vital role in the boosting of retail sales. Our AI/ML platform empowers the retailers to arrive at a suitable marketing plan. It works as a catalyst in the strategic planning process. Data analysis on buying behaviour, buying patterns etc. assists the management in marketing and customer relationship management planning.',
      img: SellingBecome,
      id: 'selling-becomes',
    },
    {
      imgpos: 'R',
      headding: <CloudComputingHead />,
      content:
        'Our AWS support team help you to design the cloud infrastructure to host your applications. With our AWS support you can put your focus on the design and development of the business application. The design, deployment and management of the AWS infrastructure will be taken care by our AWS support engineers.',
      img: CloudComputing,
      id: 'cloud-computing',
    },
    {
      imgpos: 'L',
      headding: <DataMoneyHead />,
      content:
        'We cater the finance sector with our cutting edge technology which matches the requirements of the financial institutions. The technology used in this sector must be very much secured as the data means money here. We have designed our solutions in such a way that there are no loop holes left for any kinds of frauds. So it is likely to be the extremely optimal one for the financial institutions.',
      img: DataMoney,
      id: 'data-meansMoney',
    },
  ];

  return (
    <Fragment>
      <div className='container p-0'>
        {offeringData.map((list, index) => {
          const { imgpos, headding, content, img, id } = list;
          return imgpos === 'L' ? (
            <ImgLeftComponent
              headding={headding}
              content={content}
              image={img}
              key={index}
              contentId={id}
            />
          ) : (
            <ImgRightContent
              headding={headding}
              content={content}
              image={img}
              key={index}
              contentId={id}
            />
          );
        })}
      </div>
    </Fragment>
  );
};

export default OfferingContent;
