import React, { Fragment, useState } from 'react';
import { useEffect } from 'react';
import vissionMission from '../../assets/images/vision_mission.png';
import { VisionMisionHead } from '../../assets/shapes/shape';

const HomeVissionMission = () => {
  let pathName = window.location.pathname === '/';
  const tabData = [
    {
      id: '00001',
      tabName: 'Our Vision',
      content:
        'Become a product trendsetter and be a futuristic solutions provider-using cutting edge technologies in consideration the market demands and improvement of client business.',
    },
    {
      id: '00002',
      tabName: 'Our Mission',
      content:
        'Partner with our customers to provide innovative solutions for their business needs Create next-gen solutions by using the latest software technologies available',
    },
  ];
  const [tabs, setTabs] = useState(tabData);
  const [value, setValue] = useState(0);

  const { content } = tabs[value];
  useEffect(() => {
    setTabs(tabData);
  }, []);
  return (
    <Fragment>
      <div className='container'>
        <section
          className={
            !pathName
              ? 'section-home-vission-mission gap gap-bottom'
              : 'section-home-vission-mission gap'
          }
        >
          <div className='row'>
            <div className='col-md-4 col-xs-12 pr-0 sm-hidden'>
              <img
                className='img-fluid '
                src={vissionMission}
                alt='vissionMission'
              />
            </div>
            <div className='col-md-8 col-xs-12 pl-0 vission-bg sm-visible'>
              <header>
                <VisionMisionHead />
              </header>
              <article>
                {/* ------- Tab ------ */}
                <div className='vis-mis-btn'>
                  {tabs.map((list, index) => {
                    return (
                      <button
                        onClick={() => setValue(index)}
                        key={list.id}
                        className={`btn ${index === value ? 'active' : ''}`}
                      >
                        {list.tabName}
                      </button>
                    );
                  })}
                </div>
                <div className='tab-content'>{content}</div>
              </article>
            </div>
          </div>
        </section>
      </div>
    </Fragment>
  );
};

export default HomeVissionMission;
