import React, { Fragment } from 'react';

const InputForm = ({
  onChange,
  name,
  placeholder,
  type,
  required,
  value,
  textArea,
  errorMessage,
  focused,
  handleFocus,
  label,
  labelFor,
  loading,
  row,
  ...inputProps
}) => {
  return (
    <Fragment>
      {textArea ? (
        <>
          {labelFor && <label htmlFor={name}>{label}</label>}
          <textarea
            className='form-control'
            cols='30'
            required={required}
            rows={row && row ? row : '8'}
            {...inputProps}
            name={name}
            onChange={onChange}
            placeholder={placeholder}
            value={value}
            focused={focused ? focused.toString() : ''}
            onBlur={handleFocus}
            disabled={loading}
          />
          <span>{errorMessage}</span>
        </>
      ) : (
        <>
          {labelFor && <label htmlFor={name}>{label}</label>}
          <input
            className='form-control'
            {...inputProps}
            type={type}
            name={name}
            required={required}
            onChange={onChange}
            placeholder={placeholder}
            value={value}
            focused={focused ? focused.toString() : ''}
            onBlur={handleFocus}
            disabled={loading}
          />
          <span>{errorMessage}</span>
        </>
      )}
    </Fragment>
  );
};

export default InputForm;
