import React, {
  Fragment,
  useEffect,
  useLayoutEffect,
  useState,
  useRef,
} from 'react';
import {
  HalfCircle,
  PinkCircle,
  PurpleCircle,
  TriangleBlue,
  TrianglePurple,
  YellowCircle,
} from '../assets/shapes/shape';

import gsap from 'gsap';
const tl = gsap.timeline();

const BgAnimation = () => {
  const [icons, setIcons] = useState([]);
  let root = useRef(null);
  let movingIcons = [
    { src: <YellowCircle /> },
    { src: <TrianglePurple /> },
    { src: <HalfCircle /> },
    { src: <TriangleBlue /> },
    { src: <PurpleCircle /> },
    { src: <PinkCircle /> },
  ];

  // console.log(icons);
  const shapeIcons = () => {
    const shapedIco = [...movingIcons]
      .sort(() => Math.random() - 0.5)
      .map((shapesIcons) => ({ ...shapesIcons, id: Math.random() }));

    setIcons(shapedIco);
  };

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      tl.from('.shapesIcons', { opacity: 0, duration: 0.5 }, 0.5);
    }, root);

    return () => ctx.revert();
  }, []);

  useEffect(() => {
    shapeIcons();
    tl.restart();
    const timeS = setInterval(() => {
      tl.restart();
      shapeIcons();
    }, 60000);
    return () => {
      clearInterval(timeS);
    };
  }, []);

  return (
    <div ref={root}>
      <div className='shapesIcons'>
        {icons.map((icon) => {
          return <Fragment key={icon.id}>{icon.src}</Fragment>;
        })}
      </div>
    </div>
  );
};

export default BgAnimation;
