import { Fragment } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation, EffectFade } from 'swiper';
import roboHead from '../assets/images/roboHead.png';
import newTech from '../assets/images/newTech.png';
import weOffer from '../assets/images/we-offer.png';
import businessWomen from '../assets/images/business_women.png';
import weDeliver from '../assets/images/we-deliver.png';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import BannerSlide from './BannerSlide';

const Banner = () => {
  const BannerData = [
    {
      subHead: 'Future Innovations',
      mainHead: 'TECHNOLOGY',
      mainHeadLite: 'REVOLUTION',
      paraGraph:
        'The future is fast approaching, and a new era of digital innovation and disruption is here.',
      image: roboHead,
    },
    {
      subHead: 'Future Technology',
      mainHead: 'WE DELIVER',
      mainHeadLite: 'TECHNOLOGY',
      paraGraph:
        'We specialise in delivering the innovation you need with cost effective technology.',
      image: weOffer,
    },
    {
      subHead: 'Unparalleled Technology',
      mainHead: 'WE DELIVER',
      mainHeadLite: 'COMPETENCE!',
      paraGraph: 'Unparalleled technology expertise and variety of experience.',
      image: newTech,
    },
    {
      subHead: 'Grow Your Business',
      mainHead: 'BUSINESS',
      mainHeadLite: 'CATALYST',
      paraGraph:
        'Exponentially grow your business with our Enterprise Plus products.',
      image: businessWomen,
    },
    {
      subHead: 'Efficient Delivery',
      mainHead: 'WE DELIVER',
      mainHeadLite: 'EXCELLENCE!',
      paraGraph:
        'Agile, real-time, scalable processes to ensure efficient delivery',
      image: weDeliver,
    },
  ];
  return (
    <Fragment>
      <Swiper
        id='home'
        centeredSlides={true}
        grabCursor={true}
        slidesPerView={'auto'}
        autoplay={{
          delay: 5000,
          disableOnInteraction: true,
        }}
        pagination={{
          clickable: true,
        }}
        speed={2000}
        loop={true}
        navigation={false}
        modules={[Pagination, Navigation, Autoplay, EffectFade]}
        effect={'fade'}
        className='mySwiper'
      >
        {BannerData &&
          BannerData.map((list, index) => {
            const { subHead, mainHead, mainHeadLite, paraGraph, image } = list;
            return (
              <SwiperSlide key={index}>
                <BannerSlide
                  subHead={subHead}
                  mainHead={mainHead}
                  mainHeadLite={mainHeadLite}
                  paraGraph={paraGraph}
                  image={image}
                />
              </SwiperSlide>
            );
          })}
      </Swiper>
    </Fragment>
  );
};

export default Banner;
