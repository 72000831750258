import React, { Fragment, useLayoutEffect, useRef } from 'react';
import aboutImg from '../../assets/images/about_company.png';
import { SmallDotGroup, AfterBlue } from '../../assets/shapes/shape';
import gsap from 'gsap';
import { Power2 } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { HashLink } from 'react-router-hash-link';

gsap.registerPlugin(ScrollTrigger);
const HomeAboutSection = () => {
  let root = useRef(null);
  let imgRef = useRef(null);
  useLayoutEffect(() => {
    const tl = gsap.timeline();
    let ctx = gsap.context(() => {
      tl.to('.img-about', {
        opacity: 1,
        duration: 0.7,
        scale: 1,
        ease: Power2.easeInOut,
        delay: 0.5,
      }).from(
        '.anim-text',
        {
          opacity: 0,
          duration: 0.2,
          ease: Power2.easeInOut,
          stagger: 0.1,
        },
        0.3
      );
    }, root);
    return () => ctx.revert();
  }, []);

  return (
    <Fragment>
      <div className='container' ref={root}>
        <section className='section-about gap'>
          <div className='row'>
            <div className='col-lg-6'>
              <img
                ref={imgRef}
                className='img-fluid img-about opacity-0'
                src={aboutImg}
                alt='#'
              />
            </div>
            <div className='col-lg-6'>
              <header className='anim-text'>
                <SmallDotGroup />
                <h2>
                  About<span>Global Business Solutions</span>
                </h2>
                <div className='after-blue'>
                  <AfterBlue />
                </div>
              </header>
              <article>
                <p className='anim-text'>
                  <strong>GBS-PLUS</strong> is a software solutions and product
                  company based in Trivandrum. We focus on providing innovative
                  solutions using the latest cutting edge software technologies
                  available.
                </p>
                <p className='mt-4 anim-text'>
                  100+ years of collective experience by the founding team
                  members in designing and developing software products and
                  solutions.
                </p>
                <HashLink
                  to='/about#about'
                  className='mt-4 btn button-primary-link '
                >
                  About GBS
                </HashLink>
              </article>
            </div>
          </div>
        </section>
      </div>
    </Fragment>
  );
};

export default HomeAboutSection;
