import React, { Fragment, useState, useEffect } from 'react';
import classNames from 'classnames';
import { BannerSmall, ClientsComponents, FooterCommon } from '../compontents';
import ClientImage from '../assets/images/client_banner_img.png';
import Navbar from '../compontents/Navbar';

const Clients = () => {
  const [scrolled, setScrolled] = useState();
  const classes = classNames('clients', {
    scrolled: scrolled,
  });
  useEffect((_) => {
    const handleScroll = (_) => {
      if (window.pageYOffset > 1) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return (_) => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Fragment>
      <Navbar />
      <BannerSmall
        image={ClientImage}
        styleClass={`${classes}`}
        headding={'Clients'}
      />
      <ClientsComponents />
      <FooterCommon />
    </Fragment>
  );
};

export default Clients;
