import React, { Fragment, useState, useEffect } from 'react';
import classNames from 'classnames';
import {
  BannerSmall,
  CaseStudiesComponent,
  FooterCommon,
} from '../compontents';
import Navbar from '../compontents/Navbar';
import CasestudyImg from '../assets/images/case_study_banner.png';

const Casestudies = () => {
  const [scrolled, setScrolled] = useState();
  const classes = classNames('clients', {
    scrolled: scrolled,
  });
  useEffect((_) => {
    const handleScroll = (_) => {
      if (window.pageYOffset > 95) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return (_) => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <Fragment>
      <Navbar />
      <BannerSmall
        image={CasestudyImg}
        styleClass={`${classes}`}
        headding={'Case Studies'}
      />
      <CaseStudiesComponent />
      <FooterCommon />
    </Fragment>
  );
};

export default Casestudies;
