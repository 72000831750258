import React, { Fragment, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import FooterLogo from '../assets/images/footer-logo.svg';
import { NewsSend } from '../assets/shapes/shape';
import Careers from './careers/Careers';
import SocialMedia from './SocialMedia';
import { ToastContainer, toast } from 'react-toastify';
import emailjs from '@emailjs/browser';

const FooterCommon = () => {
  const [visibleRight, setVisibleRight] = useState(false);
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);
  const handleCareer = () => {
    document.body.classList.add('scroll-prevent');
    setVisibleRight(true);
  };
  const modalHidden = () => {
    setVisibleRight(false);
    document.body.classList.remove('scroll-prevent');
  };
  const form = useRef();
  const formSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    if (value) {
      emailjs
        .sendForm(
          'service_6gy576j',
          'template_wqeo6ji',
          form.current,
          'OqKKVqNfYeJkrXUaF'
        )
        .then(
          (result) => {
            if (result.text === 'OK') {
              toast.success('Super!!! 👍 Subscribed!');
              setLoading(false);
              setValue('');
            }
          },
          (error) => {
            toast.error(error.text);
          }
        );
      e.target.reset();
    }
  };
  return (
    <Fragment>
      <ToastContainer />
      {/* ========= CareersPage ========= */}
      <Careers visibleRight={visibleRight} modalHidden={modalHidden} />
      {/* ========= CareersPage ========= */}
      <div className='footer-container gap'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-4'>
              <img
                className='img-fluid footer-logo'
                src={FooterLogo}
                alt='footerLogo'
              />
              <p>
                <strong>GBS-PLUS</strong> is a software solutions and product
                company based in Trivandrum. We focus on providing innovative
                solutions using the latest cutting edge software technologies
                available.
              </p>
              <div className='footer-social-media'>
                <SocialMedia />
              </div>
            </div>
            <div className='col-md-4'>
              <div className='row'>
                <div className='col-lg-6 mx-auto'>
                  <h6>Usefull Links</h6>
                  <ul className='usefull-links'>
                    <li>
                      <HashLink to='/#home'> Home</HashLink>
                    </li>
                    <li>
                      <Link onClick={handleCareer}> Careers</Link>{' '}
                    </li>
                    <li>
                      <HashLink
                        to='/about#team'
                        scroll={(el) => el.scrollIntoView({ behavior: 'auto' })}
                      >
                        {' '}
                        Our Teams
                      </HashLink>
                    </li>
                    <li>
                      <HashLink to='/contact-us#contactUs'>
                        {' '}
                        Contact Us
                      </HashLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='col-md-4'>
              <h6>Subscribe Newsletter</h6>
              <p className='footer-sn'>
                Get access to exclusive special deals and be the first to know
                about our latest content
              </p>
              <form onSubmit={formSubmit} ref={form}>
                <div className='form-group mt-4'>
                  <div className='form-container'>
                    <input
                      type='email'
                      className='form-control'
                      placeholder='Enter Email'
                      name='subscribe'
                      value={value}
                      onChange={(e) => setValue(e.target.value)}
                      required
                    />
                    <button className='btn'>
                      {loading ? (
                        <i
                          className='pi pi-spin pi-spinner'
                          style={{ fontSize: '1.5rem' }}
                        ></i>
                      ) : (
                        <NewsSend />
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className='gap-8'></div>
      <div className='container'>
        <div className='sup'></div>
        <div className='footer-copyrite'>
          <div className='cprite'>
            © {new Date().getFullYear()} gbs-plus.com copyrighted. All rights
            reserved
          </div>
          <div className='terms-condition'>
            <Link to={''}>Terms & Conditions</Link>
            <Link to={''}>Privacy Policy</Link>
          </div>
        </div>
        <div className='gap-8'></div>
      </div>
    </Fragment>
  );
};

export default FooterCommon;
