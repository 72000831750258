import client_01 from '../../assets/images/clients_pagelist/01.png'
import client_02 from '../../assets/images/clients_pagelist/02.png'
import client_03 from '../../assets/images/clients_pagelist/03.png'
import client_04 from '../../assets/images/clients_pagelist/04.png'
import client_05 from '../../assets/images/clients_pagelist/05.png'
import client_06 from '../../assets/images/clients_pagelist/06.png'
import client_07 from '../../assets/images/clients_pagelist/07.png'
import client_08 from '../../assets/images/clients_pagelist/08.png'
import client_09 from '../../assets/images/clients_pagelist/09.png'
import client_10 from '../../assets/images/clients_pagelist/10.png'
import client_11 from '../../assets/images/clients_pagelist/11.png'
import client_12 from '../../assets/images/clients_pagelist/12.png'
import client_13 from '../../assets/images/clients_pagelist/13.png'
import client_14 from '../../assets/images/clients_pagelist/14.png'
import client_15 from '../../assets/images/clients_pagelist/15.png'
import client_16 from '../../assets/images/clients_pagelist/16.png'
import client_17 from '../../assets/images/clients_pagelist/17.png'
import client_18 from '../../assets/images/clients_pagelist/18.png'
// ===========================================================

import partner_1 from '../../assets/images/clients_pagelist/partners/01.png'
import partner_2 from '../../assets/images/clients_pagelist/partners/02.png'
import partner_3 from '../../assets/images/clients_pagelist/partners/03.png'
import partner_4 from '../../assets/images/clients_pagelist/partners/04.png'
import partner_5 from '../../assets/images/clients_pagelist/partners/05.png'
import partner_6 from '../../assets/images/clients_pagelist/partners/06.png'
import partner_7 from '../../assets/images/clients_pagelist/partners/07.png'

export const tabData = [
  {
    id: '00001',
    tabName: 'Clients',
    content: [
      client_01,
      client_02,
      client_03,
      client_04,
      client_05,
      client_06,
      client_07,
      client_08,
      client_09,
      client_10,
      client_11,
      client_12,
      client_13,
      client_14,
      client_15,
      client_16,
      client_17,
      client_18,
    ],
  },
  {
    id: '00002',
    tabName: 'Partners',
    content: [
      partner_1,
      partner_2,
      partner_3,
      partner_4,
      partner_5,
      partner_6,
      partner_7,
    ],
  },
]
