import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { tabData } from './tabData';
import gsap from 'gsap';
import { Power2 } from 'gsap';
const ClientsTab = () => {
  const [tabs, setTabs] = useState(tabData);
  const [value, setValue] = useState(0);
  const tl = gsap.timeline();
  let root = useRef(null);
  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      tl.from(
        '.img-fluid',
        {
          opacity: 0,
          y: -10,
          ease: Power2.easeInOut,
          stagger: 0.1,
          duration: 0.4,
        },
        0.1
      );
    }, root);
    return () => ctx.revert();
  }, [value]);

  const { content } = tabs[value];
  useEffect(() => {
    setTabs(tabData);
  }, []);

  return (
    <div className='client-tab-container' ref={root}>
      <div className='tab-wrapper'>
        <article>
          {/* ------- Tab ------ */}
          <div className='cli-par-btn'>
            {tabs.map((list, index) => {
              return (
                <button
                  onClick={() => setValue(index)}
                  key={list.id}
                  className={`btn ${index === value ? 'active' : ''}`}
                >
                  {list.tabName}
                </button>
              );
            })}
          </div>
          <div className='tab-content'>
            <div className='row gap-bottom'>
              {content.map((items, index) => {
                return (
                  <div key={index} className='col-lg-4 mb-4'>
                    <img
                      className='img-fluid'
                      src={items}
                      alt={`clietns_${index}`}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </article>
      </div>
    </div>
  );
};

export default ClientsTab;
