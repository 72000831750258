import React, {
  Fragment,
  useRef,
  useLayoutEffect,
  useState,
  useEffect,
} from 'react';
import { Link, useLocation } from 'react-router-dom';
import menuData from './menuData';
import Logo from '../assets/images/logo.svg';
import { MenuActive } from '../assets/shapes/shape';
import BgAnimation from './BgAnimation';
import gsap from 'gsap';
import { Elastic, Power4 } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);
const Navbar = () => {
  const [mobileMenu, setMobileMenu] = useState(false);
  const [addClass, setAddClass] = useState('');
  const location = useLocation();
  let root = useRef(null);
  const menu = useRef(null);
  const menuList = useRef(null);
  const secMenubg = useRef(null);
  const tl = useRef(null);
  const mobileMenuVisible = () => {
    setMobileMenu(!mobileMenu);
    setAddClass('mobile-menu-flashing');
    setTimeout(() => {
      setAddClass('');
    }, 1300);
  };

  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  };
  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
    );
    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
    return windowDimensions;
  }
  const { width } = useWindowDimensions();

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      gsap.from('.menu-active', {
        duration: 0.5,
        opacity: 0,
        x: 10,
        ease: Elastic.easeOut,
      });
    }, root);
    return () => ctx.revert();
  }, []);

  useEffect(() => {
    tl.current = gsap.timeline({ paused: true });

    tl.current
      .to(menu.current, {
        left: 0,
        duration: 0.5,
        ease: Power4.easeInOut,
      })
      .to(secMenubg.current, {
        width: '300%',
        duration: 0.5,
        ease: Power4.easeInOut,
        stagger: 0.7,
      })
      .to(menuList.current, {
        opacity: 1,
        duration: 0.5,
        ease: Power4.easeInOut,
      });
  }, []);
  useEffect(() => {
    mobileMenu && tl.current.play();
    !mobileMenu && tl.current.reverse();
  }, [mobileMenu]);
  useEffect(() => {
    const showAnim = gsap
      .from(
        '.scroll-fix',
        {
          yPercent: width <= 992 ? 0 : -150,
          paused: true,
          duration: 0.5,
        },
        0.1
      )
      .progress(1);

    ScrollTrigger.create({
      start: 'top top',
      end: '9999',
      toggleClass: { targets: '.scroll-fix', className: 'active' },
      onUpdate: (self) => {
        self.direction === -1 ? showAnim.play() : showAnim.reverse();
      },
    });
  }, [width]);
  return (
    <Fragment>
      <div className='nav-container scroll-fix ' ref={root}>
        <div className='container'>
          <div className='menu-list-container'>
            <div className='brand'>
              <Link
                to='/'
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                  });
                }}
              >
                <img className='img-fluid' src={Logo} alt='logo' />
              </Link>
            </div>
            <div
              className={`btn mobile-menu ${addClass}`}
              onClick={mobileMenuVisible}
            >
              <span className='guide-icon'></span>
              <span className='guide-icon'></span>
              <span className='guide-icon'></span>
            </div>
            <div ref={menu} className='menu-list'>
              <div ref={secMenubg} className='menu-secandary-bg'></div>
              <ul
                ref={menuList}
                className={width >= 992 ? 'menu-big-visible' : ''}
              >
                {mobileMenu && (
                  <div className='position-relative opacity-5'>
                    <BgAnimation />
                  </div>
                )}
                {menuData &&
                  menuData.map((menu, index) => {
                    const { menuName, path } = menu;
                    return (
                      <li
                        key={index}
                        className={location.pathname === path ? 'active' : ''}
                        onClick={() => {
                          window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: 'smooth',
                          });
                        }}
                      >
                        <div className='menu-active'>
                          {location.pathname === path && <MenuActive />}
                        </div>

                        <Link to={path}>{menuName}</Link>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Navbar;
