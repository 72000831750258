import { Fragment } from 'react';

import {
  Banner,
  FooterCommon,
  HomeAboutSection,
  HomeCompleteProject,
  HomePlatforms,
  HomeVissionMission,
} from '../compontents';
import Navbar from '../compontents/Navbar';

const Home = () => {
  return (
    <Fragment>
      <Navbar />
      <Banner />
      <HomeAboutSection />
      <HomeVissionMission />
      <HomeCompleteProject />
      <HomePlatforms />
      <FooterCommon />
    </Fragment>
  );
};

export default Home;
