import {
  ServiceAggregationCasestudyHead,
  OnlineExaminationCasestudyHead,
  SienceIndiaCasestudyHead,
  FinancialSectorCasestudyHead,
} from '../../assets/shapes/shape';

import SACImage from '../../assets/images/case_service_aggregation.png';
import OnlineExamImage from '../../assets/images/case_online_exam.png';
import ScienceIndiaImage from '../../assets/images/case_science_india.png';
import FinancialSector from '../../assets/images/case_financial_sector.png';

const caseStudiesData = [
  {
    id: 'serviceAggregation',
    title: <ServiceAggregationCasestudyHead />,
    image: SACImage,
    content:
      'Requirements of various services and their aggregation to the service providers are a painful activity. In most cases, there will be service requirements and the requestor is unable to find the right service provider. Here comes the importance of a common platform to connect the requests and services.',
  },
  {
    id: 'onlineExamination',
    title: <OnlineExaminationCasestudyHead />,
    image: OnlineExamImage,
    content:
      'The usage of the internet has the utmost importance in education in recent years. Educational institutions are making use of the internet as a tool for facilitating and evaluating. Various web and mobile applications have evolved to cater to the requirements of the educational sector.',
  },
  {
    id: 'sienceIndia',
    title: <SienceIndiaCasestudyHead />,
    image: ScienceIndiaImage,
    content:
      'India is blessed with its immense talent in science since the ancient days. There are famous personalities who take India to the world in the field of science. India wants to continue tapping the talents for keeping the same tempo in science and its technological application.',
  },
  {
    id: 'financialSector',
    title: <FinancialSectorCasestudyHead />,
    image: FinancialSector,
    content:
      'In current banking systems, even though large volume of Customer and Transaction data are available, Machine Learning (ML) techniques are not utilized. Financial institutions are in search of such system which can improve their performance and make use of the unexploited potentials.',
  },
];

export default caseStudiesData;
