import React, { useRef, Fragment, useEffect, useState } from 'react';
import caseStudyData from './caseStudySingleData';
import { useNavigate, useParams } from 'react-router-dom';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { Power4 } from 'gsap';
import {
  CaseStudyIco,
  CaseStudyIco_01,
  CaseStudyIco_02,
  CaseStudyIco_03,
  CaseStudyIco_04,
  SmallDotGroup,
} from '../../assets/shapes/shape';
gsap.registerPlugin(ScrollTrigger);

const CaseStudySingle = () => {
  const [modal, setModal] = useState(false);
  const movingBg = useRef(null);
  const movingBgLight = useRef(null);
  const modalWrapper = useRef(null);
  const scrollVisible = useRef(null);
  const tl = useRef(null);
  const navigate = useNavigate();
  const { caseStudiesId } = useParams();
  // console.log('useParams', caseStudiesId);

  // Find Id form data
  const caseStudy = caseStudyData.find((list) => list.id === caseStudiesId);
  // console.log('caseStudy', caseStudy);

  // data destructure
  const {
    image,
    id,
    title,
    background,
    theChallenge,
    rootCaseAnalysis,
    solution,
    solutionImage,
    results,
    customerReference,
  } = caseStudy;

  useEffect(() => {
    tl.current = gsap.timeline({ paused: true });
    tl.current
      .to(movingBg.current, {
        width: '100%',
        duration: 0.5,
        ease: Power4.easeInOut,
      })
      .to(movingBgLight.current, {
        width: '100%',
        duration: 0.5,
        ease: Power4.easeInOut,
      })
      .to(modalWrapper.current, {
        opacity: 1,
        duration: 0.5,
        ease: Power4.easeInOut,
      });

    setModal(tl.current.play());
  }, []);
  useEffect(() => {
    modal && tl.current.play();
    !modal && tl.current.reverse();
  }, [modal]);

  useEffect(() => {
    const showAnim = gsap
      .from('.back', {
        yPercent: -150,
        paused: true,
        duration: 0.2,
      })
      .progress(1);

    ScrollTrigger.create({
      start: 'top top',
      end: 9999,
      onUpdate: (self) => {
        self.direction === -1 ? showAnim.play() : showAnim.reverse();
      },
    });
  }, []);
  const modalVisible = (caseStudiesId) => {
    setModal(!modal);
    setTimeout(() => {
      navigate(`/case-studies#${caseStudiesId}`);
    }, 1600);
  };

  return (
    <Fragment>
      <div className='blank-bg'></div>
      <div ref={movingBg} className='moving-bg'></div>
      <div ref={movingBgLight} className='moving-bg-light'></div>
      <div className='modal-wrapper gap-4' ref={modalWrapper}>
        <div className='case_study_content'>
          <div className='container'>
            <div className='back' ref={scrollVisible}>
              <button
                onClick={() => modalVisible(caseStudiesId)}
                className='btn btn-link'
              >
                ⟵ Back to Case Studies
              </button>
            </div>
            <div className='row'>
              <div className={id === 'sienceIndia' ? 'col-lg-6' : 'col-lg-4'}>
                <div className='image_wrapper text-center'>
                  <img className='img-fluid' src={image} alt={'image'} />
                </div>
              </div>
              <div className={id === 'sienceIndia' ? 'col-lg-6' : 'col-lg-8'}>
                <div className='case_header'>
                  <SmallDotGroup />
                  <h2 className='mb-5'>{title}</h2>
                </div>
                <div className='sec_background mb-4'>
                  <div className='h6 text-primary'>Background</div>
                  <p className='text-justify'>{background}</p>
                </div>
                <div className='sec_theChallenge mb-4'>
                  <div className='h6 text-primary'>The Challenge</div>
                  {theChallenge &&
                    theChallenge.map((list, index) => {
                      return (
                        <p key={index} className='text-justify'>
                          {list}
                        </p>
                      );
                    })}
                </div>
              </div>
              <div
                className={solutionImage.length > 0 ? 'col-lg-8' : 'col-lg-12'}
              >
                <div className='sec_rootcase mt-4'>
                  <div className='h6 text-primary'>Root Cause Analysis</div>
                  <ul>
                    {rootCaseAnalysis &&
                      rootCaseAnalysis.map((list, index) => {
                        return <li key={index}>{list}</li>;
                      })}
                  </ul>
                </div>
                <div className='sec_solution'>
                  <div className='h6 mt-4 text-primary'>Solution</div>
                  {solution.map((list, index) => {
                    return (
                      <p key={index} className='sec_content text-justify'>
                        {list}
                      </p>
                    );
                  })}
                </div>
                <div className='sec_result'>
                  <div className='h6  mt-4 text-primary'>Results/Benefits</div>
                  <div className='sec_para_one'>
                    <p className='text-justify'>{results?.paraOne}</p>
                  </div>
                  <div className='sec_result_list'>
                    <ul>
                      {results?.lists.map((list, index) => {
                        return <li key={index}>{list}</li>;
                      })}
                    </ul>
                  </div>
                </div>
              </div>
              <div className={solutionImage.length > 0 ? 'col-lg-4' : 'd-none'}>
                <img
                  className='solution_image img-fluid'
                  src={solutionImage}
                  alt='solutionImage'
                />
              </div>
              <div className='col-lg-12'>
                <div className='sec_para_two mt-2 mb-4'>
                  <p className='text-justify'>{results?.paraTwo}</p>
                </div>
                <div className='sec_customerReference'>
                  <div className='h6 text-primary mb-2'>Customer Reference</div>
                  <div>
                    <strong>Application :</strong>{' '}
                    {customerReference?.application}
                  </div>
                  <div>
                    <strong>Customer :</strong> {customerReference?.customer}
                  </div>
                </div>
              </div>
            </div>
            <hr className='mt-4' />
            <div className='row mt-4 pb-1'>
              {customerReference &&
                customerReference.count.map((list, index) => {
                  const { service, provider, icon } = list;
                  return (
                    <div key={index} className='col-lg-3 mt-3 mb-4'>
                      <div className='d-flex align-items-center'>
                        <div className='icon'>
                          {icon === 'service' ? (
                            <CaseStudyIco_01 />
                          ) : icon === 'serviceIssue' ? (
                            <CaseStudyIco_02 />
                          ) : icon === 'androidUser' ? (
                            <CaseStudyIco_03 />
                          ) : icon === 'iOSUser' ? (
                            <CaseStudyIco_04 />
                          ) : (
                            ''
                          )}
                        </div>
                        <div className='count ml-3'>
                          <strong className='fw-bold'>{service}</strong>
                          <p className='m-0'>{provider}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CaseStudySingle;
