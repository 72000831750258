import React, { useLayoutEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import data from './caseStudiesData';
import gsap from 'gsap';
import { Power2 } from 'gsap';
const CasestudyComponent = () => {
  const tl = gsap.timeline();
  let root = useRef(null);
  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      tl.from(
        '.img-fluid',
        {
          opacity: 0,
          ease: Power2.easeIn,
        },
        '0.1'
      )
        .to('.gap-bottom', { opacity: 1 }, 0.1)
        .to('header', { opacity: 1, ease: Power2.easeIn }, 0.1)
        .to('.p_content', { opacity: 1, ease: Power2.easeIn }, 0.3)
        .to('button', { opacity: 1, ease: Power2.easeIn }, 0.3);
    }, root);
    return () => ctx.revert();
  }, []);

  const navigate = useNavigate();
  const handleDetails = (id) => {
    navigate(id);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };
  return (
    <div className='casestudy-wrapper' id='caseStudies' ref={root}>
      <div className='container'>
        {data.map((list, index) => {
          const { id, image, content, title } = list;
          return (
            <div className='gap gap-bottom' key={id}>
              <div className={index % 2 === 0 ? 'row' : 'row row-reverse'}>
                <div className='col-lg-6'>
                  <img
                    className={
                      id === 'serviceAggregation' || id === 'onlineExamination'
                        ? 'img-fluid img-height-fix'
                        : 'img-fluid'
                    }
                    src={image}
                    alt='image'
                  />
                </div>
                <div className='col-lg-6'>
                  <header className='mb-4 opacity-0'>{title}</header>
                  <p className='mb-4 p_content opacity-0'>{content}</p>

                  <button
                    onClick={() => handleDetails(id)}
                    className='btn button-primary-link mb-4 opacity-0'
                  >
                    ReadMore
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CasestudyComponent;
