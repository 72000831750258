import React, { Fragment } from 'react';
import {
  BannerSmall,
  ContactFormComponents,
  FooterCommon,
} from '../compontents';
import Navbar from '../compontents/Navbar';
import ContactImage from '../assets/images/contact_banner_img.png';

const ContactUs = () => {
  return (
    <Fragment>
      <Navbar />
      <BannerSmall
        headding={'Contact Us'}
        image={ContactImage}
        styleClass={'about'}
      />
      <ContactFormComponents />
      <FooterCommon />
    </Fragment>
  );
};

export default ContactUs;
