import SACImage from '../../assets/images/case_service_aggregation.png';
import OnlineExamImage from '../../assets/images/case_online_exam.png';
import ScienceIndiaImage from '../../assets/images/case_science_india.png';
import FinancialSector from '../../assets/images/case_financial_sector.png';
import fixit from '../../assets/images/fixit.png';
import VVM from '../../assets/images/vvm.png';

const caseStudiesData = [
  {
    id: 'serviceAggregation',
    title: 'Service Aggregation Case Study',
    image: SACImage,
    background:
      'Requirements of various services and their aggregation to the service providers are a painful activity. In most cases, there will be service requirements and the requestor is unable to find the right service provider. Here comes the importance of a common platform to connect the requests and services.',
    theChallenge: [
      'In UAE there was a continuous requirement of utility services. Service requesters were desperately in search of the right service providers. The service providers were scattered around and not been exposed to the market requirements. A person who is looking for utility service may find a service provider who is nearby his location, but the chances are less. He needs to put more manual efforts in finding such an option within a stipulated time frame.',
      'People in such a busy city may not find time to fix their utility requirements themselves by putting this much effort. But it is so important to fix these kinds of issues as early as possible though it seems tiny but the significance is high in daily life. Geographical barriers to access the right services were the bottleneck in some instances. Cost of services, getting the right person at the right time and place, skilled personnel, payment modes, tracking of services, convenience in doing such activity, communication barriers, etc. were severely affected the service requesters.',
      'On the other hand service providers were not been exposed widely and finding it difficult to be at the right place and at the right time. They were also putting a lot of effort and money to be visible in the market by using conventional methods like advertising, mouth publicity and other promotional activities. They were encountering payment collection issues, communication barriers, etc. in their daily functions. Most of them neither been aware of the requirements in the market nor communicates with the service requests on time.',
      'Both service requesters and service providers were in dark; how to gratify their sides. Both of them were in search of a common platform to showcase their needs and services. They wanted to have a spacious one or the other in virtual to solve the issue.',
    ],
    rootCaseAnalysis: [
      'Service requesters were unable to connect with the right service providers.',
      'Service providers were scattered around without exposed to the requesters.',
      'Unavailability of a proper aggregation platform to connect both requesters and providers.',
    ],
    solution: [
      'A common platform to aggregate the service requesters and service providers will solve this issue. We have been introduced to this issue and we have suggested a fine-tuned system–FixT. This system prevails with the gaps and could come out with a viable interphase, which terminates the problems. FixT could address the issues faced by both service requesters and service providers. It manages to aggregate both beneficiaries into its common platform by servicing their needs without adding any cost. It enables the compilation of the scattered service providers in this platform, which makes them visible to the persons who are desperately looking for the services. It acts as a single solution for multiple problems.',
    ],
    solutionImage: fixit,
    results: {
      paraOne:
        'The system resulted in tremendous changes in the market. Now people are finding the right services nearby within no time. Service providers benefited. The major outcomes after implementing the system are as below.',
      lists: [
        'Mapping of various services and requests.',
        'Requestor has the privilege to select the service providers from several options.',
        'Requestor can have one-to-one communication with the provider.',
        'The requestor can go with multiple service requests at a time.',
        'Easy mode of tracking each request',
        ' Notifications at each stage of service workflow.',
        'Track service provider location by time & distance.',
        'Customer accepted the rate before starting the work which will avoid disputes after completion.',
        'Secured payment methods.',
        'Grievances registration and improvement system.',
        'Savings to service providers on publicity and promotions.',
      ],
      paraTwo:
        'There are 500+ service providers in Dubai is getting benefitted by FixT App and resolves more than 1000 service-related problems. The download figures (Android: 800+ & iOS: 400+) show its acceptance among the users; the service requesters.',
    },
    customerReference: {
      application: 'FixT (Android and iOS)',
      customer: 'Fixer Portal LLC, Dubai, UAE.',
      count: [
        {
          service: '500+',
          provider: 'Service Providers',
          icon: 'service',
        },
        {
          service: '1000',
          provider: 'Service Issues',
          icon: 'serviceIssue',
        },
        {
          service: '800+',
          provider: 'Android Users',
          icon: 'androidUser',
        },
        {
          service: '400+',
          provider: 'iOS Users',
          icon: 'iOSUser',
        },
      ],
    },
  },
  {
    id: 'onlineExamination',
    title: 'Online Examination Case Study',
    image: OnlineExamImage,
    background:
      'The usage of the internet has the utmost importance in education in recent years. Educational institutions are making use of the internet as a tool for facilitating and evaluating. Various web and mobile applications have evolved to cater to the requirements of the educational sector.',
    theChallenge: [
      'Vidyarthi Vigyan Manthan (VVM) used to conduct talent examinations every year to spread interest among students in science subjects. It was tedious work for the administration team to conduct and coordinate such examinations across India. Since its being a pen and paper mode examination, the back-end work was very painful activity for the VVM team.',
      'The task starts with the registering of schools, students from respective schools, etc. This manual process takes much time for the VVM team to come up with a complete list of schools and students enrolling for the examination. Next is the conducting of the examination at various centres across India. VVM had to deploy more manpower and time to conduct examination in this fashion. Publishing of result takes more time as everything was manual method.',
      'On the other hand, the Digital India Mission was pressing to change the pen and paper mode to an online platform, wherein the reach, acceptance, quickness and transparency are justified.',
    ],
    rootCaseAnalysis: [
      'Need for a change in platform to an online one as a part of Digital India Mission.',
      'Manual administration of conducting examinations.',
      'Geographical barriers in conducting exams on time were one of the major hurdles.',
      'High cost in terms of logistics, stationery used and manpower.',
    ],
    solution: [
      'The ultimate objective was to develop a digital platform to conduct examinations to spread interest among students in science subjects across India. VVM is the government body to carry out this task in pan India basis. GBS Plus has taken up the challenge and derived a solution in the form of a mobile application in both the Android and iOS platform to cater to this need.',
      'It simplified the registration process in the first phase. Schools can register in the mobile application themselves. In the next step, registered schools will enrol students from their respective schools. Registered students will receive the VVM credentials to attend the examination. Students can log in with the received credentials and have to update their profile, device details, etc. for taking the examination.',
      'As simple as that, students could attend the examination after the authentication step in the application. Internet facility is required only at the start and end of the examination; in between it works without internet. Enrolled students divided into two categories. Junior section comprises of students from classes 6 th to 8 th and senior section of students from 9 th to 11 th .',
    ],
    solutionImage: VVM,
    results: {
      paraOne:
        'The new application has helped VVM in conducting science examinations in a simple way than earlier. It is the largest online science examination for the school students in the world. The major benefits VVM had after the application release is as below.',
      lists: [
        'It could reduce the cost of examination.',
        'It could cover a vast geographical area with minimal efforts.',
        'It works in multiple devices and multiple OS.',
        'The data usage for this examination app is very minimal.',
        'The TAT of conducting examinations has come down drastically.',
        'It can handle a large number of concurrent users.',
        'It could derive the results instantly, which reduced the result waiting time.',
        'It carries an inbuilt data analytics mechanism.',
        'It could reduce the manpower used for conducting the examinations.',
      ],
      paraTwo:
        'This initiative has covered 2000 centres for engaging more than 90,000 students in pan India at a time. More than 1 Lakh Android users and nearly 3000 iOS users benefited from this App. The increasing figures in users show the application’s acceptance and success.',
    },
    customerReference: {
      application: 'VVM (Android and iOS)',
      customer: 'Vidyarthi Vigyan Manthan',
      count: [
        {
          service: '3,23,059',
          provider: 'Students Attended',
          icon: '',
        },
        {
          service: '91,876',
          provider: 'Registered Students',
          icon: '',
        },
        {
          service: '4,541',
          provider: 'Registered Schools',
          icon: '',
        },
        {
          service: '459',
          provider: 'Registered District',
          icon: '',
        },
        {
          service: '1,00,790',
          provider: 'Android Users',
          icon: '',
        },
        {
          service: '2,617',
          provider: 'iOS Users',
          icon: '',
        },
        {
          service: '16',
          provider: 'Ubuntu Users',
          icon: '',
        },
        {
          service: '9,187',
          provider: 'Windows Users',
          icon: '',
        },
      ],
    },
  },
  {
    id: 'sienceIndia',
    title: 'Science India Case Study',
    image: ScienceIndiaImage,
    background:
      'India is blessed with its immense talent in science since the ancient days. There are famous personalities who take India to the world in the field of science. India wants to continue tapping the talents for keeping the same tempo in science and its technological application.',
    theChallenge: [
      'Vijnana Bharati (Vibha) in association with NCSTC, CSIR and CSIR-IICT wanted to have common platform for the science interested students and those who are good in guiding and mentoring the students. The vision behind this movement is to provide proper mentoring to science-loving students and show them the different arenas of science and its application in modern technology.',
      'The portal demands a forum in which every student will have a mentor who will help them in taking right decision in studies and researches. There shall be an aggregation of students and the experts in science like scientists, research fellows, professors and PhD holders. The portal should motivate and inspire young students to find science as an interesting career option and to generate curiosity among them to explore deep into the world of science.',
      'Vibha was looking for mentoring and networking of students, science related news and information sharing, blogs, bringing creative ideas, etc. under one roof, a virtual science world. Ultimately the attributes of this portal should guide the students self-motivated towards a knowledge gaining or setting a career path in science field.',
    ],
    rootCaseAnalysis: [
      'Lack of proper mentoring to students in early-stage itself to sharpen and polish the talent in the science subjects.',
      'Lack of connection of the students with eminent personalities in science subjects.',
      'Proper aggregation/connection platform for science-loving students and science wizards.',
    ],
    solution: [
      'The web portal designed and developed by GBS Plus acts as an aggregator of science- loving students and their mentors. It could make a pool of talents in science which meets the galaxy of eminent personalities in the field of science. The prime requirement of mentoring the science-loving students with the right mentors has solved by this web portal. It could engage students by motivating with the presence of the Great Science Gurus of the country in this virtual world of science.',
      'Another major attribute of this portal is the counselling by professional counsellors and psychiatrists to students and their parents to guide and solve the issues concerning to students related to adolescence, character, behaviour, etc. It has a science community wherein a massive network of selected science students from all the corners of the country betrothed in sharing novel ideas, news and information related to science and its application in modern technology.',
      'Students can post science-related blogs which will be edited by an editorial board comprising editors who have proven their talents in science and technology. The science every week section aims at involving students with creative initiatives to solve the problems of society through the application of science. Thoughts and innovation zone encourages a discussion forum wherein new ideas of students will be discussed, nurtured and mentored by celebrated science faculties and scientists to polish it into a distinctive one. Articles and interviews, both textual & video, on different areas of science, by eminent scientists and subject experts will be published daily and a dedicated area is there for the inspirational stories of the scientists who have changed the world.',
    ],
    solutionImage: '',
    results: {
      paraOne:
        'The web portal of Science India is dedicated to the nation by the Honourable Vice- President of India, Shri. Venkaiah Naidu. The major outcomes of this initiative are as below.',
      lists: [
        'Connected the science-loving students with eminent personalities in science',
        'Mentorship program helped students to have a proper mentor to guide to heights.',
        'One to one communication in the form of text chat or video chat between students and their respective mentors.',
        'The Great Science Gurus of the country are also involved lively in this virtual science world.',
        'Renowned professional counsellors and psychiatrists are engaged in this portal to provide guidance to students and their parents.',
        'The science community in this portal engages students from different parts of India with fresh ideas, news and information for mutual benefits.',
        'The science every week section talks about problems faced by the society and invites creative initiatives from students to solve such issues.',
        'The attributes in this portal helps students to nurture themselves in the field of science and explore the different arenas of science.',
      ],
      paraTwo:
        'This initiative could cover 521+ Science correspondents, 19892+ Student aspirants and 790+ Mentors. 1420+ Schools registered and holding the hands with this initiative. The increasing figures in users show the acceptance and success of this initiative. The token of appreciations from several areas encouraged the initiative in greater sense and spirits.',
    },
    customerReference: {
      application: 'Science India Web Portal (www.scienceindia.in)',
      customer: 'Vibha (Vijnana Bharati)',
      count: [
        {
          service: '521+',
          provider: 'Registered Science Correspondent',
          icon: '',
        },
        {
          service: '19892+',
          provider: 'Registered Students',
          icon: '',
        },
        {
          service: '1420+',
          provider: 'Registered Schools',
          icon: '',
        },
        {
          service: '790+',
          provider: 'Registered Mentors',
          icon: '',
        },
      ],
    },
  },
  {
    id: 'financialSector',
    title: 'ML - in the Financial Sector Case Study',
    image: FinancialSector,
    background:
      'In current banking systems, even though large volume of Customer and Transaction data are available, Machine Learning (ML) techniques are not utilized. Financial institutions are in search of such system which can improve their performance and make use of the unexploited potentials.',
    theChallenge: [
      'The present scenario in the banking system is not tapping the benefits of Machine Learning in its full strength. Though the customer segmentation is happening by data analytics, it is in the form of non-category segmentation. Dynamic segmentation is the key in financial analysis but the existing systems are not making use of the same.',
      'Data is very vital in the financial sector as data means money in every stage. This is the reason why a proper data management is demanded by this sector. Data analysis in its exact form can do magic in their business. That is why financial institutions take serious steps in data mining and analysis.',
      'But the real challenge in data mining and analysis is getting the right segmentation of customers to push the products. So the financial institutions were looking for a data analytics platform which could do multiple tasks. They wanted to have a data analytics platform with customer categorized segmentation and which can recommend suitable additional products to the customers.',
    ],
    rootCaseAnalysis: [
      'There are hidden categories which are difficult to identify manually from a large data set.',
      'There is a large amount of data available scattered and not used properly.',
      'Frequent reestablishment of categories is not possible with manual analytics.',
    ],
    solution: [
      'Machine Learning based dynamic segmentation of customers helps the financial institutions to get a dynamic perspective about the different kinds of customers, lending patterns for different categories of customer, risk assessment based on patterns, generate inputs for creating variations of the standard product offerings, generate inputs for cross-selling products.',
      'The new compacted system find outs hidden categories within mass data which will provide very appropriate and proper data analytics. Various correlation methods (like Pearson correlation) are used for finding out the similarities of the products and related products are also recommended to existing customers. This way the bank can ensure that relevant products are recommended to relevant customers at the right time.',
      'Machine Learning services grab data from the financial institution’s server and recommends suitable additional products to customers. These recommendations help the staffs to suggest products to customers and customers also have insights to go with their choices of products. It is integrated with “Amazon Alexa Service” in its advanced form (ML – CUI Interfacing Model) which delivers automated and easy outcomes.',
    ],
    solutionImage: '',
    results: {
      paraOne:
        'The new system could bring magical benefits to its users at the very early stage itself. The major /outcomes of this initiative are as below.',
      lists: [
        'It could analyse scattered and mass data available.',
        'It could come out with a dynamic segmentation.',
        'One to one communication in the form of text chat or video chat between students and their respective mentors.',
        'The system shows the number of segments discovered by the algorithm based on the new list of attributes selected.',
        'The significant values of the attributes defining each of the segments, the number of customers in each of these segments and product affinity of each of the segments can be visualized.',
        'The science community in this portal engages students from different parts of India with fresh ideas, news and information for mutual benefits.',
        'It could reinstate categories which were not possible manually.',
        'It could recommend financial products based on the machine learning analysis of mass data.',
        'Insights from the dynamic segmentation can be used for cross-selling or up-selling products to different groups of customers.',
        'By segmenting the customer dynamically and accurately, they could be offered with more customized products and services.',
      ],
      paraTwo:
        'Few of the financial institutions have benefitted from the “Public Beta” stage itself.',
    },
    customerReference: {
      application: 'INSIGHT PLUS (SaaS Model)',
      customer: 'MBFC (India)',
      count: [
        {
          service: '500+',
          provider: 'Customer Benefitted',
          icon: '',
        },
        {
          service: '$ 1 Million',
          provider: 'Possible Loan Distribution',
          icon: '',
        },
        {
          service: '50+',
          provider: 'No of products considered',
          icon: '',
        },
        {
          service: '05+',
          provider: 'No of segments identified',
          icon: '',
        },
      ],
    },
  },
];
export default caseStudiesData;
