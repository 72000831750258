import React, { Fragment } from 'react';
import { PlatformsHead, SmallDotGroup } from '../../assets/shapes/shape';
import ServiceAggregator from '../../assets/images/service_aggregator.png';
import ALML from '../../assets/images/al_ml.png';
import DataAnalytics from '../../assets/images/data_analytics.png';
import Online from '../../assets/images/online_assessment.png';
import { HashLink } from 'react-router-hash-link';
import BgAnimation from '../BgAnimation';

const HomePlatforms = () => {
  const platformsData = [
    {
      id: 'serviceAggregation',
      image: ServiceAggregator,
      headding: 'Service Aggregation Platform',
      content:
        'Service Aggregation Platform is a place where the service needed customers can find...',
    },
    {
      id: 'aiAndMlPlatform',
      image: ALML,
      headding: 'AI&ML~Platform',
      content:
        'AI & ML Platform enables the prediction of customer behaviour and buying pattern. Reta...',
    },
    {
      id: 'dataAnalyticsPlatform',
      image: DataAnalytics,
      headding: 'Data Analytics~Platform',
      content:
        'Data Analytics Platform performs a simplified process of data consolidation...',
    },
    {
      id: 'onlineAssessment',
      image: Online,
      headding: 'Online Assessment Platform',
      content:
        'Online assessment platform provides a well-defined surface for E-learning and knowledg...',
    },
  ];

  return (
    <Fragment>
      <div className='gap'>
        <div className='homeplatforms-section-bg'>
          <div className='position-relative'>
            <BgAnimation />
          </div>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='position-absolute right-0'>
                  <SmallDotGroup />
                </div>
                <header>
                  <PlatformsHead />
                </header>
              </div>
            </div>
            <div className='row platforms-wrapper'>
              {platformsData.map((list, index) => {
                const { id, image, headding, content } = list;
                let HeadSplit = headding.split('~');
                return (
                  <div key={index} className='col-md-6'>
                    <HashLink to={`/platforms#${id}`}>
                      <div className='row'>
                        <div className='col-lg-4'>
                          <img className='img-fluid' src={image} alt='img' />
                        </div>
                        <div className='col-lg-8'>
                          <h2 className='headH2'>
                            {headding.includes('AI&ML') ||
                            headding.includes('Data') ? (
                              <>
                                {HeadSplit[0]} <br /> {HeadSplit[1]}
                              </>
                            ) : (
                              headding
                            )}
                          </h2>
                          <p>{content}</p>
                          Read More →
                        </div>
                      </div>
                    </HashLink>
                  </div>
                );
              })}
            </div>
            <div className='position-relative'>
              <BgAnimation />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default HomePlatforms;
