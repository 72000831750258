import React, { Fragment, useState } from 'react';
import { Sidebar } from 'primereact/sidebar';
import './career.css';
import Logo from '../../assets/images/logo.png';
import InputForm from '../contactComponents/InputForm';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
const url = 'https://gbsmail.gbs-plus.com:8443/v1/career';
const Careers = ({ modalHidden, visibleRight }) => {
  const [visible_Right, setVisible_Right] = useState(false);
  const [modalId, setModalId] = useState(null);
  const [formToggle, setFormToggle] = useState(false);
  const [focused, setFocused] = useState(false);
  const [loading, setLoading] = useState(false);
  const [contactForm, setContactForm] = useState({
    userRole: '',
    firstName: '',
    lastName: '',
    emailAddress: '',
    phoneNumber: '',
    resumeLink: '',
  });
  const jobData = [
    {
      id: '0001',
      role: 'Technical Architect (Java Stream)',
      location: 'Trivandrum',
      type: 'Full time, Remote',
      jobOpenings: 'Technical Architect (Java Stream)',
      preferedSkils: [
        '5-7 years of relevant experience',
        'Outstanding skills in Core Java and J2EE (JMS, Servlets, JSP)',
        'Experience in Spring and Hibernate frameworks',
        'Experience in web services (SOAP & REST)',
        'Excellent analytical and problem solving skills',
        'Understanding of CI / CDusing Jenkins and other related tools',
        'Experience in Agile and Waterfall methodologies',
      ],
      education: 'Any Degree',
      discription:
        ' Design, develop and tests new applications or enhancements to existing software applications Working knowledge of microservices architecture pattern The primary Skills that we are looking for is: Java + Spring Boot + Microservices + REST API & Angular.',
    },
  ];

  const inputs = [
    {
      id: 1,
      name: 'userRole',
      type: 'text',
      placeholder: 'Enter Role You Are Applying For',
      label: 'Applying For (Role)',
      labelFor: true,
      required: true,
      errorMessage: 'Please Enter Your Role',
    },
    {
      id: 2,
      name: 'firstName',
      type: 'text',
      placeholder: 'Enter Your First Name',
      label: 'First Name',
      labelFor: true,
      required: true,
      errorMessage: 'Please Enter Your First Name',
    },
    {
      id: 3,
      name: 'lastName',
      type: 'text',
      placeholder: 'Enter Your Last Name',
      label: 'Last Name',
      labelFor: true,
      required: true,
      errorMessage: 'Please Enter Your Last Name',
    },
    {
      id: 4,
      name: 'emailAddress',
      type: 'email',
      placeholder: 'Enter Your Email Address',
      label: 'Email Address',
      labelFor: true,
      required: true,
      errorMessage: 'Please Enter Your Email Address',
    },
    {
      id: 5,
      name: 'phoneNumber',
      type: 'text',
      placeholder: 'Enter Your Phone Number',
      label: 'Phone Number',
      labelFor: true,
      pattern: '^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$',
      required: true,
      errorMessage: 'Please Enter Your Valid Phone Number',
    },
    {
      id: 6,
      name: 'resumeLink',
      type: 'text',
      placeholder:
        'Enter Your Resume Link (Please make sure the link accesable without permission)',
      label: 'Resume Link',
      labelFor: true,
      required: true,
      textArea: true,
      row: 2,
      errorMessage: 'Please Enter Your Resume Link',
    },
  ];

  const modalOpen = (id) => {
    // console.log('first', id);
    setModalId(id);
    setVisible_Right(true);
  };
  const modalCancel = () => {
    setVisible_Right(false);
    modalHidden(false);
  };
  const formToggles = () => {
    setFormToggle(true);
  };
  const formClose = () => {
    setFormToggle(false);
    setFocused(false);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setContactForm({ ...contactForm, [name]: value });
  };

  const handleFocus = (e) => {
    setFocused(true);
  };

  const formSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const {
      userRole,
      firstName,
      lastName,
      emailAddress,
      phoneNumber,
      resumeLink,
    } = contactForm;
    let file_name = '';
    const postData = {
      applying_for: userRole,
      first_name: firstName,
      last_name: lastName,
      email: emailAddress,
      phone: phoneNumber,
      link: resumeLink,
      file_name: file_name,
    };

    try {
      await axios.post(url, postData).then((response) => {
        console.log('response', response);
        if (response.status === 200) {
          toast.success("That's Great!!! 👍 We Will Contact Soon");
        }
        setLoading(false);
        setFocused(false);
        setContactForm({
          userRole: '',
          firstName: '',
          lastName: '',
          emailAddress: '',
          phoneNumber: '',
          resumeLink: '',
        });
      });
    } catch (error) {
      toast.error(`Oh No! 😥 ${error.message}`);
      setLoading(false);
      setFocused(false);
      // setContactForm({
      //   userRole: '',
      //   firstName: '',
      //   lastName: '',
      //   emailAddress: '',
      //   phoneNumber: '',
      //   resumeLink: '',
      // });
    }
  };

  return (
    <Fragment>
      <ToastContainer />
      <Sidebar visible={visibleRight} position='right' onHide={modalHidden}>
        <div className='container jobs-opening'>
          <h4>Jobs Opening</h4>
          <hr />
          <div className='jobs__wrapper'>
            <div className='header'>
              <div>Role</div>
              <div>Location</div>
              <div>Type</div>
              <div>Details</div>
            </div>
            {jobData.map((list) => {
              const { role, location, type, id } = list;
              return (
                <Fragment key={id}>
                  <div className='content'>
                    <div>{role}</div>
                    <div>{location}</div>
                    <div>{type}</div>
                    <div>
                      <button
                        onClick={() => modalOpen(id)}
                        className='btn btn-sm btn-outline-info'
                      >
                        Details
                      </button>
                    </div>
                  </div>
                </Fragment>
              );
            })}
          </div>
        </div>
      </Sidebar>

      {/* apply Form */}
      <Sidebar
        visible={visible_Right}
        position='left'
        onHide={() => setVisible_Right(false)}
      >
        {jobData.map((details) => {
          const { jobOpenings, preferedSkils, education, discription, id } =
            details;
          let openId = modalId === id;

          return (
            openId && (
              <div key={id} className='jobdetails__wrapper'>
                <div className='container'>
                  <div className='row'>
                    <div className='col-lg-8 mx-auto jobdetails__content__wrapper'>
                      <img className='mb-5 img-fluid' src={Logo} alt={'logo'} />
                      <h1 className='text-muted mb-4 fw-bold'>{jobOpenings}</h1>
                      <hr />

                      {!formToggle && (
                        <>
                          <strong>Brief Description</strong>
                          <p>{discription && discription}</p>
                          <div className='skills'>
                            <strong>Skills</strong>
                            {preferedSkils &&
                              preferedSkils.map((list, index) => {
                                return (
                                  <ul key={index}>
                                    <li>{list}</li>
                                  </ul>
                                );
                              })}
                          </div>
                          <div className='education'>
                            <strong>Education</strong>
                            <p>{education && education}</p>
                          </div>
                          <hr className='mt-4 mb-4' />
                          <div className='visible-xs pb-4'>
                            Contact to HR :{' '}
                            <Link
                              className='text-secondary'
                              href='tel:+91 755 888 1001'
                            >
                              +91 755 888 1001
                            </Link>
                          </div>
                          <div className='jobApply__wrapper'>
                            <div className='d-flex'>
                              <div className='hide-xs'>
                                Contact to HR :
                                <a
                                  className='text-secondary'
                                  href='tel:+91 755 888 1001'
                                >
                                  +91 755 888 1001
                                </a>
                              </div>
                              <div className='ml-auto'>
                                <button
                                  onClick={modalCancel}
                                  className='btn btn-outline-danger mr-3'
                                >
                                  Cancel
                                </button>
                                <button
                                  onClick={() => setVisible_Right(false)}
                                  className='btn btn-outline-secondary mr-3'
                                >
                                  Close
                                </button>
                                <button
                                  onClick={formToggles}
                                  className='btn btn-outline-info'
                                >
                                  Apply Now
                                </button>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      {formToggle && (
                        <div className='job_apply_form_wrapper'>
                          <div className='d-flex align-items-center justify-content-between mb-4'>
                            <h5 className='text-secondary'>Send a request</h5>
                            <button
                              className='btn btn-sm btn-outline-info'
                              onClick={formClose}
                            >
                              ← Back
                            </button>
                          </div>

                          <form
                            id='form'
                            onSubmit={formSubmit}
                            className='mt-3'
                          >
                            <div className='row'>
                              {inputs.map((input) => {
                                const {
                                  id,
                                  name,
                                  type,
                                  required,
                                  placeholder,
                                  label,
                                  labelFor,
                                  textArea,
                                  row,
                                } = input;
                                return (
                                  <div
                                    key={id}
                                    className={
                                      textArea
                                        ? 'col-lg-12 mb-4'
                                        : 'col-lg-6 mb-4'
                                    }
                                  >
                                    <InputForm
                                      name={name}
                                      type={type}
                                      {...input}
                                      required={required}
                                      placeholder={placeholder}
                                      label={label}
                                      labelFor={labelFor}
                                      textArea={textArea}
                                      row={row}
                                      onChange={handleChange}
                                      value={contactForm[name]}
                                      handleFocus={handleFocus}
                                      focused={focused}
                                      loading={loading}
                                    />
                                  </div>
                                );
                              })}
                            </div>
                            <div className='apply_button_wrapper'>
                              <button className='btn button-primary-link'>
                                Submit
                              </button>
                            </div>
                          </form>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )
          );
        })}
      </Sidebar>
    </Fragment>
  );
};

export default Careers;
