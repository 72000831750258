import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import offeringsData from './offeringsData';
import {
  AWSHead,
  EducationHead,
  EnergyUtilitiesHead,
  FinanceHead,
  RetailHead,
} from '../../assets/shapes/shape';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { Power4 } from 'gsap';
gsap.registerPlugin(ScrollTrigger);
const OfferingsSinglePage = () => {
  const { offeringsId } = useParams();
  // console.log({ offeringsId });

  // Find Id form data
  const offering =
    offeringsData && offeringsData.find((list) => list.id === offeringsId);
  // ============
  // data destructure
  const { image, id, content, name, subContent, products } = offering;
  let HeadingSplit = name.split('~');
  // ============
  const [modal, setModal] = useState(false);
  const movingBg = useRef(null);
  const movingBgLight = useRef(null);
  const modalWrapper = useRef(null);
  const tl = useRef(null);
  const navigate = useNavigate();
  const scrollVisible = useRef(null);
  useEffect(() => {
    tl.current = gsap.timeline({ paused: true });
    tl.current
      .to(movingBg.current, {
        width: '100%',
        duration: 0.5,
        ease: Power4.easeInOut,
      })
      .to(movingBgLight.current, {
        width: '100%',
        duration: 0.5,
        ease: Power4.easeInOut,
      })
      .to(modalWrapper.current, {
        opacity: 1,
        duration: 0.5,
        ease: Power4.easeInOut,
      });

    setModal(tl.current.play());
  }, []);
  useEffect(() => {
    modal && tl.current.play();
    !modal && tl.current.reverse();
  }, [modal]);

  useEffect(() => {
    const showAnim = gsap
      .from('.back', {
        yPercent: -150,
        paused: true,
        duration: 0.2,
      })
      .progress(1);

    ScrollTrigger.create({
      start: 'top top',
      end: 9999,
      onUpdate: (self) => {
        self.direction === -1 ? showAnim.play() : showAnim.reverse();
      },
    });
  }, []);
  const modalVisible = (offeringsId) => {
    setModal(!modal);
    setTimeout(() => {
      navigate(`/offerings#${offeringsId}`);
    }, 1600);
  };
  return (
    <Fragment>
      <div className='blank-bg'></div>
      <div ref={movingBg} className='moving-bg'></div>
      <div ref={movingBgLight} className='moving-bg-light'></div>
      <div className='modal-wrapper' ref={modalWrapper}>
        <div className='back' ref={scrollVisible}>
          <button
            onClick={() => modalVisible(offeringsId)}
            className='btn btn-link'
          >
            ⟵ Back to Offerings
          </button>
        </div>
        {image && <img className='img-fluid' src={image && image} alt={id} />}
        <div className='modal-content-wrapper w-100'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-4'>
                {id === 'reach-customer' ? (
                  <EnergyUtilitiesHead />
                ) : id === 'teach-students' ? (
                  <EducationHead />
                ) : id === 'selling-becomes' ? (
                  <RetailHead />
                ) : id === 'cloud-computing' ? (
                  <AWSHead />
                ) : id === 'data-meansMoney' ? (
                  <FinanceHead />
                ) : (
                  ''
                )}
              </div>
              <div className='col-lg-8'>
                <h2>
                  {HeadingSplit[0]} <br /> {HeadingSplit[1]}
                </h2>
                <ul className='main-list'>
                  {content.map((list, index) => {
                    return <li key={index}>{list}</li>;
                  })}
                </ul>
              </div>
            </div>

            {subContent.length > 0 && (
              <div className='card gap-margin mb-5'>
                <div className='row'>
                  {subContent.map((subList, index) => {
                    return (
                      <div key={index} className='col-lg-6'>
                        <h4>{subList.subHead}</h4>
                        <ul className='card-list'>
                          {subList.subContentList.map((item, index) => {
                            return <li key={index}>{item}</li>;
                          })}
                        </ul>
                      </div>
                    );
                  })}
                </div>
                {products.length > 0 && (
                  <div className='row'>
                    <div className='products-card'>
                      {products.map((items, index) => {
                        return (
                          <div className='col-lg-12' key={index}>
                            <h6>{items.title}</h6>

                            {items.contentList.map((list, index) => {
                              return (
                                <div key={index} className='product-list'>
                                  {list}
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default OfferingsSinglePage;
