import React, { Fragment, useLayoutEffect, useRef } from 'react';
import BgAnimation from './BgAnimation';
import gsap from 'gsap';
import { Power2 } from 'gsap';
import { CircleGroup } from '../assets/shapes/shape';
import SocialMedia from './SocialMedia';
const BannerSlide = ({ subHead, mainHead, mainHeadLite, paraGraph, image }) => {
  const tl = gsap.timeline();
  let root = useRef(null);
  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      tl.from(
        '.headding-section',
        {
          opacity: 0,
          ease: Power2.easeIn,
        },
        '0.1'
      )
        .from('.sub-head', { y: -10, opacity: 0 }, 0.1)
        .from('.banner-heading', { y: 8, opacity: 0 }, 0.2)
        .from('.banner-p', { y: -10, opacity: 0 }, 0.3)
        .to('.banner-img', { opacity: 1, ease: Power2.easeIn }, '0.4');
    }, root);
    return () => ctx.revert();
  }, []);

  return (
    <Fragment>
      <div className='banner' ref={root}>
        <div className='position-relative z-top'>
          <BgAnimation />
        </div>
        <div className='container'>
          <div className='ml-4'>
            <CircleGroup />
          </div>
          <div className='d-flex align-items-center justify-content-between banner-height'>
            <div className='headding-section'>
              <Fragment>
                {subHead && <h6 className='sub-head'>{subHead}</h6>}
                <h1 className='banner-heading'>
                  {mainHead} <span>{mainHeadLite}</span>
                </h1>
                <p className='banner-p'>{paraGraph}</p>
              </Fragment>
            </div>
            <div className='mt-auto banner-img'>
              <Fragment>
                <img
                  id='myAnimate6'
                  src={image}
                  className='img-fluid'
                  alt={image}
                />
              </Fragment>
            </div>
            <SocialMedia />
          </div>
        </div>
        <div className='position-relative'>
          <BgAnimation />
        </div>
      </div>
    </Fragment>
  );
};

export default BannerSlide;
