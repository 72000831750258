import React, { Fragment } from 'react';
import { BannerSmall, FooterCommon, OfferingContent } from '../compontents';
import AboutImage from '../assets/images/offerings_banner.png';
import Navbar from '../compontents/Navbar';
const Offerings = () => {
  return (
    <Fragment>
      <Navbar />
      <BannerSmall
        image={AboutImage}
        styleClass={'offerings'}
        headding={'Offerings'}
      />
      <OfferingContent />
      <FooterCommon />
    </Fragment>
  );
};

export default Offerings;
