import ServiceAggregatonImg from '../../assets/images/platforms_service_aggregation.jpg';
import DataAnalytics from '../../assets/images/platforms_data_analytics.jpg';
import AIML from '../../assets/images/platforms_ai_ml.jpg';
import OnlineAssessment from '../../assets/images/platforms_online_assessment.jpg';

import {
  AiMLHead,
  DataAnalyticsPlatformHead,
  OnlineAssessmentHead,
  ServiceAggregationHead,
} from '../../assets/shapes/shape';
const PlatformsData = [
  {
    id: 'serviceAggregation',
    title: <ServiceAggregationHead />,
    image: ServiceAggregatonImg,
    content: [
      'Service Aggregation Platform is a place where the service needed customers can find the most suitable service providers. The platform is extended its service in all kinds of building maintenance such as plumbing, electrical, carpentry, masonry, painting, air conditioning and so on.',
      'It facilitates multiple service requests at the same time. Customer has the privilege to select the service provider based on the ratings and quality of work.',
    ],
  },
  {
    id: 'dataAnalyticsPlatform',
    title: <DataAnalyticsPlatformHead />,
    image: DataAnalytics,
    content: [
      'Data Analytics Platform performs a simplified process of data consolidation. It is designed to run in both batch and streaming mode using latest cutting edge technologies. Changes in the sources systems can be easily configured using the drag and drop based business process editor.',
      'This enables online streaming and scheduled batch/bulk processing of data. It can collect data from different sources and in multiple formats.',
    ],
  },
  {
    id: 'aiAndMlPlatform',
    title: <AiMLHead />,
    image: AIML,
    content: [
      'AI & ML Platform enables the prediction of customer behaviour and buying pattern. Retail data are unstructured in many manners and cannot be used for specific purposes as and when required. AI&ML powered automated analytics on retail data helps in cost optimization and suggest optimal sales promotion activities.',
      'AI&ML gives insights to micro data analysis to empower the strategic decision making process in terms of production, distribution, marketing and so on.',
    ],
  },
  {
    id: 'onlineAssessment',
    title: <OnlineAssessmentHead />,
    image: OnlineAssessment,
    content: [
      'Online assessment platform provides a well-defined surface for E-learning and knowledge assessments. This platform eliminates the geographical barriers and supports a large volume of concurrent users at a given point of time. Minimal data usage is the key differentiator of this kind of a structured platform.',
      'By using this platform the users can avail a number of assessment tests and the automated analysis of results are there on their fingertips. The elaborated platform delivers exceptional compatibility in all means.',
    ],
  },
];

export default PlatformsData;
