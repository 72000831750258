import React, { Fragment, useLayoutEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import gsap from 'gsap';
import { Power2 } from 'gsap';
const ImgLeftComponent = ({ headding, content, image, contentId }) => {
  const movingBg = useRef(null);
  const navigate = useNavigate();
  const tl = gsap.timeline();
  let root = useRef(null);
  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      tl.from(
        '.img-fluid',
        {
          opacity: 0,
          ease: Power2.easeIn,
        },
        '0.1'
      )
        .to('.gap-bottom', { opacity: 1 }, 0.1)
        .to('.content p', { opacity: 1, ease: Power2.easeIn }, 0.5)
        .to('.header', { opacity: 1, ease: Power2.easeIn }, 0.4)
        .to('.button-primary-link', { opacity: 1, ease: Power2.easeIn }, 0.6);
    }, root);
    return () => ctx.revert();
  }, []);
  const offeringsDetials = (contentId) => {
    navigate(contentId);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };
  return (
    <Fragment>
      <div ref={movingBg} className='moving-bg'></div>
      <div className='gap' ref={root} id={contentId}>
        <div className='gap-bottom opacity-0'>
          <div className='row flex-direction-reverse'>
            <div className='col-lg-6'>
              <img
                className='img-fluid point-events-none'
                src={image}
                alt='#'
              />
            </div>
            <div className='col-lg-6'>
              <header className='mb-4 header opacity-0'>{headding}</header>
              <article className='mb-4 content'>
                <p className='m-0'>{content}</p>
              </article>
              <button
                className='btn button-primary-link opacity-0 mb-4'
                onClick={() => offeringsDetials(contentId)}
              >
                ReadMore
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ImgLeftComponent;
