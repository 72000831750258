import React, { Fragment, useLayoutEffect, useRef } from 'react';

import PlatformsData from './PlatformsData';
import gsap from 'gsap';
import { Power2 } from 'gsap';
const PlatformsContent = () => {
  let root = useRef(null);
  useLayoutEffect(() => {
    const tl = gsap.timeline();
    let ctx = gsap.context(() => {
      tl.from(
        '.img-fluid',
        {
          opacity: 0,
          ease: Power2.easeIn,
        },
        '0.1'
      )
        .to('.gap-bottom', { opacity: 1 }, 0.1)
        .to('header', { opacity: 1, ease: Power2.easeIn }, 0.1)
        .to('.p_content', { opacity: 1, ease: Power2.easeIn }, 0.3);
    }, root);
    return () => ctx.revert();
  }, []);
  return (
    <Fragment>
      <div className='platforms-content-wrapper' ref={root}>
        <div className='container'>
          {PlatformsData.map((list, index) => {
            const { image, id, content, title } = list;
            return (
              <Fragment key={id}>
                <div
                  id={id}
                  className={
                    index % 2 === 0
                      ? 'row gap gap-bottom'
                      : 'row gap gap-bottom row-reverse'
                  }
                >
                  <div
                    className={
                      index % 2 === 0 ? 'col-lg-5 mr-auto' : 'col-lg-5 ml-auto'
                    }
                  >
                    <img className='img-fluid mb-4 ' src={image} alt={id} />
                  </div>
                  <div className='col-lg-6'>
                    <header>{title}</header>
                    <div className='p_content'>
                      {content.map((p_content, index) => {
                        return <p key={index}>{p_content}</p>;
                      })}
                    </div>
                  </div>
                </div>
              </Fragment>
            );
          })}
        </div>
      </div>
    </Fragment>
  );
};

export default PlatformsContent;
