import React, {
  Fragment,
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
} from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination, Navigation, EffectFade } from 'swiper'
import gsap from 'gsap'
import { Power2 } from 'gsap'
import 'swiper/css'
import 'swiper/css/effect-fade'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import teamPerson1 from '../../assets/images/team_person_1.png'
import teamPerson2 from '../../assets/images/team_person_2.png'
import teamPerson3 from '../../assets/images/team_person_3.png'
import teamPerson4 from '../../assets/images/team_person_4.png'
import teamPerson5 from '../../assets/images/team_person_5.png'
import teamPerson6 from '../../assets/images/team_person_6.png'
import teamPerson7 from '../../assets/images/team_person_7.png'
import teamPerson8 from '../../assets/images/team_person_8.png'
import teamPerson9 from '../../assets/images/team_person_9.png'
import teamPerson10 from '../../assets/images/team_person_10.png'
import teamPerson11 from '../../assets/images/team_person_11.png'
import teamPerson12 from '../../assets/images/team_person_12.png'
import teamPerson13 from '../../assets/images/team_person_13.png'
import teamPerson14 from '../../assets/images/team_person_14.png'
import teamPerson15 from '../../assets/images/team_person_15.png'
import teamPerson16 from '../../assets/images/team_person_16.png'
import teamPerson17 from '../../assets/images/team_person_17.png'
import teamPerson18 from '../../assets/images/team_person_18.png'
import teamPerson19 from '../../assets/images/team_person_19.png'
import teamPerson20 from '../../assets/images/team_person_20.png'
import teamPerson21 from '../../assets/images/team_person_21.png'
import teamPerson22 from '../../assets/images/team_person_22.png'
import teamPerson23 from '../../assets/images/team_person_23.png'
import teamPerson24 from '../../assets/images/team_person_24.png'
import teamPerson25 from '../../assets/images/team_person_25.png'
import teamPerson26 from '../../assets/images/team_person_26.png'
import teamPerson27 from '../../assets/images/team_person_27.png'
import teamPerson28 from '../../assets/images/team_person_28.png'
import teamPerson29 from '../../assets/images/team_person_29.png'

const AboutTeamSection = () => {
  const teamMembersList = [
    {
      member: 'GBS Family Member',
      name: 'Ramesh',
      designation: 'Senior Software Engineer(PHP)',
      image: teamPerson2,
    },
    {
      member: 'GBS Family Member',
      name: ' Arjun Babu',
      designation: 'Senior Software Architect',
      image: teamPerson3,
    },
    {
      member: 'GBS Family Member',
      name: 'Nidhin Chandran',
      designation: 'IT & HR Manager',
      image: teamPerson4,
    },
    {
      member: 'GBS Family Member',
      name: 'Aneesh',
      designation: 'Senior IOS Developer',
      image: teamPerson5,
    },
    {
      member: 'GBS Family Member',
      name: 'Aravind',
      designation: 'Senior Backend Developer',
      image: teamPerson6,
    },
    {
      member: 'GBS Family Member',
      name: 'Roshan Thomas',
      designation: 'Senior Software Tester',
      image: teamPerson7,
    },
    {
      member: 'GBS Family Member',
      name: 'Ameen',
      designation: 'Senior Software Engineer(Java)',
      image: teamPerson8,
    },
    {
      member: 'GBS Family Member',
      name: 'Nishad',
      designation: 'Senior Software Engineer(Java)',
      image: teamPerson9,
    },
    {
      member: 'GBS Family Member',
      name: 'Premkumar P',
      designation: 'Senior UI/UX Developer',
      image: teamPerson10,
    },
    {
      member: 'GBS Family Member',
      name: 'Navin',
      designation: 'Senior Machine Learning Engineer',
      image: teamPerson11,
    },
    {
      member: 'GBS Family Member',
      name: 'Geethu G',
      designation: 'Senior Software Engineer(PHP)',
      image: teamPerson12,
    },
    {
      member: 'GBS Family Member',
      name: 'Arun RV',
      designation: 'Software Tester',
      image: teamPerson13,
    },
    {
      member: 'GBS Family Member',
      name: 'Suji',
      designation: 'Software Engineer(Java)',
      image: teamPerson14,
    },
    {
      member: 'GBS Family Member',
      name: 'Iqbal Safar',
      designation: 'Software Engineer(Java)',
      image: teamPerson15,
    },
    {
      member: 'GBS Family Member',
      name: 'Anna Mathew',
      designation: 'Business Development Executive',
      image: teamPerson16,
    },
    {
      member: 'GBS Family Member',
      name: 'Nithin MV',
      designation: 'UI/UX Designer',
      image: teamPerson17,
    },
    {
      member: 'GBS Family Member',
      name: 'Nikhil PL',
      designation: 'Senior Software Engineer(Java)',
      image: teamPerson18,
    },
    {
      member: 'GBS Family Member',
      name: 'Akhil Mohan',
      designation: 'Software Engineer(React js)',
      image: teamPerson19,
    },
    {
      member: 'GBS Family Member',
      name: 'Jaba Joshini',
      designation: 'IOS Developer',
      image: teamPerson20,
    },
    {
      member: 'GBS Family Member',
      name: 'Adarsh Rajan',
      designation: 'Android Developer',
      image: teamPerson21,
    },
    {
      member: 'GBS Family Member',
      name: 'Anusree T',
      designation: 'Software Engineer(java)',
      image: teamPerson22,
    },
    {
      member: 'GBS Family Member',
      name: 'Nazmi N',
      designation: 'Software Engineer(Flutter)',
      image: teamPerson23,
    },
    {
      member: 'GBS Family Member',
      name: 'Sreejith GS',
      designation: 'Software Engineer (Python)',
      image: teamPerson24,
    },
    {
      member: 'GBS Family Member',
      name: 'Gireesh',
      designation: 'Software Solution Engineer',
      image: teamPerson25,
    },
    {
      member: 'GBS Family Member',
      name: 'Vishnu',
      designation: 'Software Engineer (Python)',
      image: teamPerson26,
    },
    {
      member: 'GBS Family Member',
      name: 'Sumair',
      designation: 'Software Engineer (Python)',
      image: teamPerson27,
    },
    {
      member: 'GBS Family Member',
      name: 'Saranya',
      designation: 'Software Tester',
      image: teamPerson28,
    },
    {
      member: 'GBS Family Member',
      name: 'Shahubanu',
      designation: 'Software Tester',
      image: teamPerson29,
    },
  ]

  const [width, setWidth] = useState(window.innerWidth)
  const updateDimensions = () => {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', updateDimensions)
    return () => window.removeEventListener('resize', updateDimensions)
  }, [])
  let root = useRef(null)
  const tl = gsap.timeline()
  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      tl.from(
        '.team-img-section',
        {
          opacity: 0,
          ease: Power2.easeIn,
          stagger: 0.1,
        },
        0.1
      )
    }, root)
    return () => ctx.revert()
  }, [])
  return (
    <Fragment>
      <div className='gap team-wrapper' ref={root} id='team'>
        <div className='container gap-bottom'>
          <header>
            <h2 className='headH2'>Team</h2>
          </header>
          <article>
            <div className='row'>
              <div className='col-lg-3'>
                <div className='md'>
                  <div className='team-img-section'>
                    <img
                      className='img-fluid'
                      src={teamPerson1}
                      alt='person_01'
                    />
                  </div>
                  <div className='team-content-section'>
                    <small className='text-secondary text-uppercase mb-1'>
                      GBS Family Member
                    </small>
                    <h6>Anil Sreedhar</h6>
                    <p>Chief Executive Officer [CEO]</p>
                  </div>
                </div>
              </div>
              <div className='col-lg-9'>
                <div className='abot-team'>
                  <Swiper
                    watchSlidesProgress={true}
                    slidesPerView={width < 991 ? 1 : 3}
                    grabCursor={true}
                    spaceBetween={30}
                    className='mySwiper'
                    autoplay={{
                      delay: 1500,
                      disableOnInteraction: false,
                      pauseOnMouseEnter: true,
                    }}
                    pagination={{
                      clickable: true,
                    }}
                    speed={500}
                    loop={teamMembersList.length > 3 ? true : false}
                    effect={'slide'}
                    modules={[Pagination, Navigation, Autoplay, EffectFade]}
                  >
                    {teamMembersList.map((list, index) => {
                      const { member, name, designation, image } = list
                      return (
                        <SwiperSlide key={index}>
                          <div className='team-img-section no-color'>
                            <img
                              className='img-fluid'
                              src={image}
                              alt={`person_${index + 1}`}
                            />
                          </div>
                          <div className='team-content-section'>
                            <small className='text-secondary text-uppercase mb-1'>
                              {member}
                            </small>
                            <h6>{name}</h6>
                            <p>{designation}</p>
                          </div>
                        </SwiperSlide>
                      )
                    })}
                  </Swiper>
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
    </Fragment>
  )
}

export default AboutTeamSection
