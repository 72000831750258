import Offerings from '../../assets/images/offering_bg.jpg';
import Education from '../../assets/images/education.jpg';
import Retail from '../../assets/images/retail.jpg';
import CloudCompute from '../../assets/images/cloud_compute_banner_img.jpg';
import FinanceBG from '../../assets/images/finance_bg_banner.jpg';

const offeringsData = [
  {
    id: 'reach-customer',
    name: 'Reach Your CustomerBefore~They Reach You.',
    image: Offerings,
    content: [
      'Best Software solutions, which are complete experiences that give your online visitors an accurate sense of your facilities and features. They are created from real passion and accurate data about your customers, and you can embed the walkthroughs in a website just like photos and videos.',
      'Getting started is easy!',
      'We make it ultra-easy to Satisfy your internal and external customers.',
      'As simple as Click to Pay',
      'Consumer Bill payment made incredibly easy. You can engage in Bill enquiry or Bill payment or Bill History with a simple to use, with a push of a button.',
      'All-in-one solution',
      'With one stake of solutions to the Internal and external consumers the All in solution stake has been immense value.',
    ],
    subContent: [
      {
        subHead: 'Pain Areas',
        subContentList: [
          'Transforming utility network',
          'Enhancing customer operations',
          'Improving generation & monitoring performances',
          'Maintaining operational excellence',
          'Industrial administration of energy and utilities faces modern challenges and issues.',
          'Human resource management gets complicated as the strength of the organization grows.',
          'Account and finance management need proper upgrades.',
          'Customer resolution in terms of billing enquiry.',
          'Customer service gets delayed because of slow access to billing history of users.',
          'Company revenue gets affected due to lack of co-ordination of different departments in terms of automation.',
        ],
      },
      {
        subHead: 'Solutions',
        subContentList: [
          'Monitor and Capture Usage',
          'Educate the Customer Base',
          'Stimulate Resourcefulness',
        ],
      },
      {
        subHead: 'Automating customer feedback management program',
        subContentList: [
          'Gather, analyze and act on customer feedback consistently.',
          'Understand the root cause behind a customer’s positive or negative feedback.',
          'Meet regulatory requirements for complaint and feedback management.',
        ],
      },
      {
        subHead: 'Billing process automation:',
        subContentList: [
          'Billing payment,billing enquiry and billing history get automated in a single database.',
          'Easily access to customers over the internet through cloud computing software.',
        ],
      },
      {
        subHead: 'Customer data get automated and efficient service:',
        subContentList: [
          'Servicing team can get into the billing history of customer from a single database.',
          'Assurance of proper service to each and every customer.',
        ],
      },
      {
        subHead: 'Digital billing and payment:',
        subContentList: [
          'Customers can easily access billing details and make payments over the internet.',
        ],
      },
      {
        subHead: 'Automative account and finance management:',
        subContentList: [
          'Everything related to accounts of the organization can be updated automatically.',
        ],
      },
      {
        subHead: 'Employee management automation:',
        subContentList: [
          'Employee details can be automated in a single database which include personal data,working days, performance and salary details.',
          'Easy analysis of the individual and overall performance of the employee.',
        ],
      },
      {
        subHead: 'Services',
        subContentList: [
          'Improving customer insight and involvement - better and easier billing , energy usage review.',
          'Giving customers more control – increase in the granularity of time-based usage and option for customers to optimise themselves.',
          'Increasing consumer expectations and concerns.',
        ],
      },
      {
        subHead: 'Achievements',
        subContentList: [
          'Improved operational efficiencies',
          'Reduced energy losses',
          'Better metering',
          'Reliable networks',
          'Healthier infrastructure',
        ],
      },
    ],
    products: [
      {
        title: 'Products',
        contentList: [
          'Energy Audit',
          'Billing & Payment through web self care',
          'Data Analysis',
          'Consumer Mobile App',
        ],
      },
    ],
  },
  {
    id: 'teach-students',
    name: 'You teach students~We take care of your technology.',
    image: Education,
    content: [
      'India holds an important place in the global education industry. India has one of the largest networks of higher education institutions in the world. Various government initiatives are being adopted to boost the growth of distance education market, besides focusing on new education techniques, such as E-learning and M-learning.',
      'We have designed solutions for various types of institutions in educational sector to carry out their day to day activities in automated and simplified manner. These have designed for better interaction between students, teachers, parents and management. It gracefully handles all the requirements of an easy management.',
    ],
    subContent: [
      {
        subHead: 'Pain Areas',
        subContentList: [
          'Absence of student grade audit',
          'Absence of parent-teacher advisory forum',
          'Lack of Academic early alerts',
          'Creation & tracking of education plan.',
          'Course / Program recommendation',
          'Burden of Administrative activities to academic staff',
          'Lack of transparency and robust reporting',
          'Attendance management of the school system.',
          'Classroom timings and the schedule of teachers.',
          'Paper work and data management such as account and student enrollment.',
          'Interaction among school management,teachers,students and parents.',
          'Management and administration of teaching and non-teaching staffs.',
          'Lack of analysis of individual and overall performance of students.',
          'Lack of co-ordination between teachers and parents in the overall growth of students.',
        ],
      },
      {
        subHead: 'Solutions',
        subContentList: [
          'Monitor and Capture Usage',
          'Bring in suitable changes across the entire academic & non-academic sections.',
          'Facilitate the reduction of human error/manual data through system automation.',
          'Integrated enterprise systems, including finance, HR, grants and budgeting systems.',
          'Implement cloud technologies to give better support to the institution’s faculty and administration and reduce administrative burden.',
          'Create a link between teachers & parents.',
        ],
      },
      {
        subHead: 'Better Management of the school administration:',
        subContentList: [
          'Gather, analyze and act on customer feedback consistently.',
          ' Minimize the manual work.',
          'Reduce manual data processing.',
          'Enable accurate data entry process.',
          'Bring in high performance in time consuming processes like account management and student enrollment.',
        ],
      },
      {
        subHead: 'Better inter-departmental communication:',
        subContentList: [
          ' Easy and hassle-free interaction among different departments handling accounts management, library management, enrollment system, teachers, parents and more.',
          'Ease of data sharing and information recovery from database.',
          'Personalized students’ experience.',
          'Students can view and analyze their individual performance on the ERP software.',
          'School administration can communicate with the students through an automated portal with the help of SMS or email.',
          'Students get information on news and recent updates related to school activities.',
        ],
      },
      {
        subHead: 'Overall school administration in an efficient way:',
        subContentList: [
          'Student enrollment at a single click of the button.',
          'Easy management of teaching and non-teaching staff.',
          'Students’ information such as personal details, attendance, homework, grades, division of classes and sections and the overall performance assessment can be viewed and retrieved easily.',
        ],
      },
      {
        subHead: 'Access for parents:',
        subContentList: [
          ' To provide access to parents for viewing children’s performance in academics.',
          'To give updates on parent-teacher meetings and assignments given to students',
        ],
      },
      {
        subHead: 'For teachers:',
        subContentList: [
          'The schedule of the teachers can be viewed and managed.',
          'Details like class timings, progress of the students and overall classroom activities could be viewed.',
        ],
      },
      {
        subHead: 'Attendance Management:',
        subContentList: [
          'Attendance of the students and the staff can be managed in an automated manner with the help of ERP software.',
        ],
      },
      {
        subHead: 'Services',
        subContentList: [
          'Enable process improvement',
          'Supporting business transformation.',
          'Increasing integration and communication throughout the enterprise.',
          'Capturing appropriate data for improved reporting and decision-making.',
          'Addressing critical issues in data, system and business process with respect to prior implementation efforts.',
        ],
      },
    ],
    products: [],
  },
  {
    id: 'selling-becomes',
    name: 'Selling becomes easy~With buying recommendations.',
    image: Retail,
    content: [
      'The Indian retail industry has emerged as one of the most dynamic and fast-paced industries due to the entry of several new players. Customers have the ever increasing choice of products at the lowest rates. India is the fifth largest preferred retail destination globally. The country is among the highest in the world in terms of per capita retail store availability.',
      'Buying recommendations can play a vital role in the boosting of retail sales. Our AI/ML platform empowers the retailers to arrive at a suitable marketing plan. It works as a catalyst in the strategic planning process. Data analysis on buying behaviour, buying patterns etc. assists the management in marketing and customer relationship management planning.',
      'With the evolution of technology, to become ahead of the competitive edge the retailers need to make more rigorous use of customer data. There comes the importance of intelligent selling models. These solutions make ease of this issue by providing insights, efficient recommendations (Buying, Sales Promotions, CRM and Finance) and predictions for strategic planning.',
      'This is where new and advanced technologies like Artificial Intelligence (AI) and Machine Learning (ML) came with bigger promises. Both are focusing on learning key aspects of customer behaviour and individual preferences and can better predict purchases and interactions. This was not possible with the earlier manual analytics. Machine Learning particularly came as the most promising technology for retailers across the niches and sizes.',
    ],
    subContent: [
      {
        subHead: 'Pain Areas',
        subContentList: [
          'AI/ML powered system provides accurate data.',
          'Data analysis is so dynamic and provides valuable inputs.',
          'Buying patterns and behaviour is derived.',
          'Arrives a structured and sophisticated strategic planning.',
        ],
      },
      {
        subHead: 'Solutions',
        subContentList: [
          'Monitor and Capture Usage',
          'Bring in suitable changes across the entire academic & non-academic sections.',
          'Facilitate the reduction of human error/manual data through system automation.',
          'Integrated enterprise systems, including finance, HR, grants and budgeting systems.',
          'Implement cloud technologies to give better support to the institution’s faculty and administration and reduce administrative burden.',
          'Create a link between teachers & parents.',
        ],
      },
      {
        subHead: 'Benefits',
        subContentList: [
          'Passes information faster than ever.',
          'Analytics becomes simpler with AI&ML.',
          'It simplifies and automates the tasks of in-shop marketing.',
          'It saves money on operational costs.',
          'Helps to convert shoppers into buyers. Increases the sales/revenue.',
          'Get proactive insights when and where you want.',
          'Efficient CRM.',
          'AI/ML technology can suggest optimal promotions that achieve category strategies and meet financial objectives.',
          'AI/ML technology can pinpoint the promotional wastage.',
          'Auto Tagging – buying recommendations auto fits the related products for customers.',
          'User history and behaviour – Price Sensitivity, Brand Affinity, Product Preferences, Cohort Behaviour.',
          'Macro Trends and Insights – AI &ML buying recommendations lights the way to insights on macro trends.',
          'NLP based intelligence – Metadata Extraction, Classifiers and Semantic Relationships.',
        ],
      },
    ],
    products: [],
  },
  {
    id: 'cloud-computing',
    name: 'Cloud Computing Platform~The Reformed Technology.',
    image: CloudCompute,
    content: [
      'Our AWS support team help you to design the cloud infrastructure to host your applications. With our AWS support you can put your focus on the design and development of the business application. The design, deployment and management of the AWS infrastructure will be taken care by our AWS support engineers.',
      'Proactive monitoring of AWS infrastructure ensures the continuous availability of deployed application and the environment. With the help of monitoring tools, the environment will be monitored and alerts will be send to indicate any kind of abnormal conditions in the infrastructure.',
      'Our AWS support team ensure you to maintain all the required security standards and alert you on issues that require attention. We will provide the architectural and operational guidance to optimize the performance so as to ensure that you can meet the demands of your customers.',
    ],
    subContent: [],
    products: [],
  },
  {
    id: 'data-meansMoney',
    name: 'Data Means Money~Be Secured.',
    image: FinanceBG,
    content: [
      'India has a diversified financial sector undergoing rapid expansion, both in terms of strong growth of existing financial services firms and new entities entering the market. The banking regulator has allowed new entities such as payments banks to be created recently thereby adding to the types of entities operating in the sector. The Government of India has introduced several reforms to liberalise, regulate and enhance this industry.',
      'With the evolution of technology, the entire industry has undergone a massive transformation that has changed the way the financial procedures are carried out and the way the financial institutions operate. The collaboration between finance and technology has led to a radical change in several aspects of finances like banking, investment, trading, crypto currency and more.',
      'We cater the finance sector with our cutting edge technology which matches the requirements of the financial institutions. The technology used in this sector must be very much secured as the data means money here. We have designed our solutions in such a way that there are no loop holes left for any kinds of frauds. So it is likely to be the extremely optimal one for the financial institutions.',
    ],
    subContent: [],
    products: [],
  },
];
export default offeringsData;
