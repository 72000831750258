import React, { useRef, useLayoutEffect, useState, Fragment } from 'react';
import MessageIco from '../../assets/images/message.png';
import gsap from 'gsap';
import { Power2 } from 'gsap';
import { OurLocationsHead } from '../../assets/shapes/shape';
import BgAnimation from '../BgAnimation';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InputForm from './InputForm';
import axios from 'axios';
const url = 'https://gbsmail.gbs-plus.com:8443/v1/contact-us';
const ContactFormComponents = () => {
  const [contactForm, setContactForm] = useState({
    userName: '',
    phoneNumber: '',
    emailAddress: '',
    company: '',
    message: '',
  });
  const [focused, setFocused] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleFocus = (e) => {
    setFocused(true);
  };
  let root = useRef(null);
  const form = useRef();
  useLayoutEffect(() => {
    const tl = gsap.timeline();
    let ctx = gsap.context(() => {
      tl.to(
        '.form-content-wrapper',
        {
          opacity: 1,
          ease: Power2.easeIn,
        },
        '0.1'
      );
      tl.to(
        '.sm-banner-btn',
        {
          opacity: 1,
          ease: Power2.easeIn,
        },
        '0.1'
      )
        .from(
          '.our-locaiton-wrapper',
          {
            opacity: 0,
            ease: Power2.easeIn,
          },
          0.1
        )
        .from(
          'center',
          {
            opacity: 0,
            ease: Power2.easeIn,
          },
          0.2
        );
    }, root);
    return () => ctx.revert();
  }, []);

  const inputs = [
    {
      id: 1,
      name: 'userName',
      type: 'text',
      placeholder: 'Name',
      label: 'Name',
      required: true,
      errorMessage: 'Please Enter Your Name',
    },
    {
      id: 2,
      name: 'emailAddress',
      type: 'email',
      placeholder: 'Email Address',
      label: 'Email Address',
      required: true,
      errorMessage: 'Please Enter Your Email Address',
    },
    {
      id: 3,
      name: 'phoneNumber',
      type: 'text',
      placeholder: 'Phone Number',
      label: 'Phone Number',
      pattern: '^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$',
      required: false,
      errorMessage: 'Please Enter Valid Phone Number',
    },
    {
      id: 4,
      name: 'company',
      type: 'text',
      placeholder: 'Company (Optional)',
      label: 'Company',
      required: false,
    },
    {
      id: 5,
      name: 'message',
      type: 'text',
      textArea: true,
      placeholder: 'Tell us about yourself',
      label: 'Message',
      required: true,
      errorMessage: 'Please Enter Your Message',
    },
  ];

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setContactForm({ ...contactForm, [name]: value });
  };

  const formSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const { userName, emailAddress, message, phoneNumber, company } =
      contactForm;
    const postData = {
      name: userName,
      email: emailAddress,
      phone: phoneNumber,
      company: company,
      message: message,
    };

    try {
      await axios.post(url, postData).then((response) => {
        console.log('response', response);
        if (response.status === 200) {
          toast.success("That's Great!!! 👍 We Will Contact Soon");
        }
        setLoading(false);
        setFocused(false);
        setContactForm({
          userName: '',
          phoneNumber: '',
          emailAddress: '',
          company: '',
          message: '',
        });
      });
    } catch (error) {
      toast.error(`Oh No! 😥 ${error.message}`);
      setLoading(false);
      setFocused(false);
      // setContactForm({
      //   userName: '',
      //   phoneNumber: '',
      //   emailAddress: '',
      //   company: '',
      //   message: '',
      // });
    }
  };

  return (
    <Fragment>
      <ToastContainer />
      <div className='form-wrapper gap ' id='contactUs' ref={root}>
        <div className='container'>
          <div className='form-content-wrapper' id='form-wrapper'>
            <div className='row'>
              <div className='col-lg-10 mx-auto'>
                <div className='row'>
                  <div className='col-lg-6 position-relative z-index-1'>
                    <h6>Contact us</h6>
                    <h2>Have a project in mind?</h2>
                    <p>
                      The right move at the right time saves your investment.{' '}
                      <br /> Live the dream of expanding your business.
                    </p>
                  </div>
                  <div className='message-ico ml-auto'>
                    <img src={MessageIco} alt='#' />
                  </div>
                </div>
                <form id='form' onSubmit={formSubmit} ref={form}>
                  <div className='position-relative zindex-0'>
                    <BgAnimation />
                  </div>
                  <div className='row'>
                    <div className='col-lg-4'>
                      <h6>GBS PLUS Pvt Ltd</h6>
                      <p>
                        WINDSOR apartment, Ground floor lower level TC no.
                        4/1256(38), Kuravankonam, Kowdiar, Trivandrum - 695003
                      </p>
                      <div className='pe'>PHONE - +91 755 888 1001</div>
                      <div className='pe'>EMAIL - hr@gbs-plus.com</div>
                    </div>
                    <div className='col-lg-8'>
                      <div className='row'>
                        {inputs.map((input) => {
                          const {
                            id,
                            name,
                            textArea,
                            placeholder,
                            type,
                            required,
                          } = input;
                          return (
                            <div
                              key={id}
                              className={textArea ? 'col-lg-12' : 'col-lg-6'}
                            >
                              <div className='form-group'>
                                <InputForm
                                  name={name}
                                  placeholder={placeholder}
                                  type={type}
                                  {...input}
                                  required={required}
                                  onChange={handleChange}
                                  value={contactForm[name]}
                                  handleFocus={handleFocus}
                                  focused={focused}
                                  loading={loading}
                                />
                              </div>
                            </div>
                          );
                        })}
                        <button
                          type='submit'
                          className='sm-banner-btn d-flex btn mt-4 align-items-center justify-content-center ml-auto'
                        >
                          {loading ? (
                            <Fragment>
                              <i
                                className='text-muted pi pi-spin pi-spinner mr-2'
                                style={{ fontSize: '14px' }}
                              ></i>{' '}
                              Sending...
                            </Fragment>
                          ) : (
                            'Send Message →'
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className='our-branch-wrapper gap'>
            <div className='row'>
              <div className='col-lg-12'>
                <center>
                  <OurLocationsHead />
                </center>
              </div>
            </div>
            <div className='gap our-locaiton-wrapper'>
              <div className='row position-relative z-index-1'>
                <div className='col-lg-4'>
                  <h5>Bangalore</h5>
                  <p>
                    Alphatech India Building No.891/A, 3rd Floor BNRK Towers,
                    7th A Main, Koramangala 1st Block, Bangalore – 560034
                  </p>
                  <div className='pe'>PHONE - +91 755 888 1002</div>
                  <div className='pe'>EMAIL - info@gbs-plus.com</div>
                </div>
                <div className='col-lg-4'>
                  <h5>Middle East</h5>
                  <p>
                    Al Zubair Building Sharjah FreeZone P.O. Box 120660,
                    Sharjah, United Arab Emirates.
                  </p>
                  <div className='pe'>PHONE - +971 55 223 9380</div>
                  <div className='pe'>EMAIL - info@gbs-plus.com</div>
                </div>
                <div className='col-lg-4'>
                  <h5>Australia New Zealand (ANZ)</h5>
                  <p>
                    2/86, Lechte Road, Mount Waverley, VIC-3149. ( Melbourne )
                  </p>
                  <div className='pe'>PHONE - +61 469 649 524</div>
                  <div className='pe'>EMAIL - info@gbs-plus.com</div>
                </div>
              </div>
            </div>
          </div>
          <div className='gap-bottom'></div>
        </div>
      </div>
    </Fragment>
  );
};

export default ContactFormComponents;
