import React, { Fragment } from 'react';
import {
  AboutContentSction,
  AboutOurPartners,
  AboutRecentlyCaseStudies,
  AboutTeamSection,
  BannerSmall,
  FooterCommon,
  HomeVissionMission,
} from '../compontents';
import AboutImage from '../assets/images/about_banner_img.png';
import Navbar from '../compontents/Navbar';

const About = () => {
  return (
    <Fragment>
      <Navbar />
      <BannerSmall
        image={AboutImage}
        styleClass={'about'}
        headding={'About Us'}
      />
      <AboutContentSction />
      <AboutTeamSection />
      <AboutOurPartners />
      <AboutRecentlyCaseStudies />
      <HomeVissionMission />
      <FooterCommon />
    </Fragment>
  );
};

export default About;
