import React, { Fragment } from 'react';
import sa_img from '../../assets/images/service_aggregation.png';
import ml_img from '../../assets/images/ml_finance.png';
import online_img from '../../assets/images/online_exam.png';
import { HashLink } from 'react-router-hash-link';

const AboutRecentlyCaseStudies = () => {
  const csestudiesData = [
    {
      images: sa_img,
      headding: 'Service Aggregation',
      content:
        'Requirements of various services and their aggregation to the service providers...',
    },
    {
      images: ml_img,
      headding: 'ML-Financial Sector',
      content:
        'In current banking systems, even though large volume of Customer and...',
    },
    {
      images: online_img,
      headding: 'Online Examination',
      content:
        'The usage of the internet has the utmost importance in education...',
    },
  ];
  return (
    <Fragment>
      <div className='gap'>
        <div className='container gap-bottom recent-wrapper'>
          <header>
            <h2 className='headH2 text-center'>
              Recent Completed <br /> Case Studies
            </h2>
          </header>
          <div className='row'>
            {csestudiesData.map((list, index) => {
              const { headding, images, content } = list;
              return (
                <div className='col-lg-4' key={index}>
                  <img className='img-fluid' src={images} alt={headding} />
                  <div className='text-center mt-4 rcc'>
                    <h6>{headding}</h6>
                    <p>{content}</p>
                  </div>
                </div>
              );
            })}
            <div className='col-lg-12 text-center'>
              <HashLink
                to='/case-studies#caseStudies'
                className='btn button-primary-link'
              >
                View All Casestudies
              </HashLink>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AboutRecentlyCaseStudies;
